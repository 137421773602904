import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PrismicQueryParams } from "../types";

// Define a service using a base URL and expected endpoints
export const documentApi = createApi({
  reducerPath: "prismicApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH + "/prismic",
  }),
  tagTypes: ["Product", "ProductGroup", "Fabric", "ProductOption"],
  endpoints: (builder) => ({
    getDocumentByType: builder.query<any, PrismicQueryParams>({
      query: ({ type, lang, filterKey, filterValue, fetchLinks }) => {
        let url = `/content/${type}/${lang || 'en-us'}`;
        if (filterKey && filterValue) {
          url += `/${filterKey}/${filterValue}`;
        }
        if (fetchLinks) {
          url += `/${fetchLinks.join(",")}`;
        }
        return url;
      },
    }),
    getTranslations: builder.query<any, PrismicQueryParams>({
      query: ({ lang }) => {
        return `/content/translations/${lang || ""}`;
      },
    }),
  }),
});

export const { useGetDocumentByTypeQuery, useGetTranslationsQuery } =
  documentApi;
