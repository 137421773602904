import { Node, PBRMaterial, Texture } from "@babylonjs/core";
import { Color3, Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh.js";
import {
  ProductOption,
  ProductMeshFabric,
  FabricColorOption,
  Product,
} from "../../types";
import { Scene } from "@babylonjs/core/scene";
import { ILoadedModel } from "react-babylonjs";
import { verbose } from "./config";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";

function hasProductOptionMeshes(
  productOption: ProductOption,
  filterProductOptionMeshes?: boolean, // apply extra ProductOption mesh filter
) {
  return (
    getProductOptionMeshes(productOption, filterProductOptionMeshes).length > 0
  );
}

function getProductOptionMeshes(
  productOption: ProductOption,
  filterProductOptionMeshes?: boolean, // apply extra ProductOption mesh filter
): Array<string> {
  if (
    Array.isArray(productOption.data.meshes) &&
    productOption.data.meshes.length
  ) {
    return productOption.data.meshes
      .filter((productOptionMesh) => typeof productOptionMesh.mesh === "string")
      .filter((productOptionMesh) =>
        filterProductOptionMeshes === true
          ? productOptionMesh.apply_fabric_only !== true
          : true,
      )
      .map((productOptionMesh) => productOptionMesh.mesh);
  }
  return [];
}

export function getAllDisplayableProductOptionMeshes(
  productOptions: Array<ProductOption>,
): Array<string> {
  return productOptions
    .filter((items) => hasProductOptionMeshes(items, true))
    .map((items) => getProductOptionMeshes(items, true))
    .flat()
    .flatMap((meshName) => meshName.split(",")) // Split any comma-separated mesh names and flatten again
    .map((meshName) => meshName.trim()); // Trim whitespace from each name to clean up the data
}

// Get ProductOption meshes with a specific fabric color
export function getProductOptionMeshesWithFabric(
  productOption: ProductOption,
  productMeshFabric: ProductMeshFabric,
): Array<string> {
  if (
    Array.isArray(productOption.data.meshes) &&
    productOption.data.meshes.length
  ) {
    return productOption.data.meshes
      .filter((productOptionMesh) => {
        if (typeof productOptionMesh.mesh !== "string") {
          return false;
        }
        return productOptionMesh.variant === productMeshFabric;
      })
      .map((productOptionMesh) => productOptionMesh.mesh);
  }
  return [];
}

// Get ProductOption meshes and related fabric
function getProductOptionMeshesFabrics(
  productOption: ProductOption,
): Array<[string, FabricColorOption, number][]> {
  if (
    Array.isArray(productOption.data.meshes) &&
    productOption.data.meshes.length
  ) {
    return productOption.data.meshes
      .filter((productOptionMesh) => {
        if (typeof productOptionMesh.mesh !== "string") {
          return false;
        }
        return true;
      })
      .map((productOptionMesh) => {
        let fabric: FabricColorOption = "main";
        switch (productOptionMesh.variant) {
          case "fabric_configured":
            fabric = productOption.data.color_option || "main";
            if (fabric !== "main" && fabric !== "contrast") {
              console.warn(
                "Possible invalid color name:",
                fabric,
                "on mesh:",
                productOptionMesh,
              );
            }
            break;
          case "main_fabric_static":
            fabric = "main";
            break;
          case "contrast_fabric_static":
            fabric = "contrast";
            break;
          case "static":
            // Using the Fabric id so the function does need the full fabric list. Translate Fabric id to web_format later...
            fabric = productOptionMesh.static_fabric?.id
              ? `fabric_id:${productOptionMesh.static_fabric.id}`
              : "static";
            break;
          case "logo_mesh":
            fabric = "static";
            break;
          default:
            fabric = "main";
        }
        // productOptionMesh.mesh could be a list of mesh names split by ',' so always return an array of mesh names.
        return productOptionMesh.mesh
          .split(",")
          .map((item) => [
            item.trim(),
            fabric,
            productOptionMesh.priority || 0,
          ]);
      });
  }
  return [];
}

export function getAllProductOptionMeshesFabrics(
  productOptions: Array<ProductOption>,
): Map<string, FabricColorOption> {
  const result = productOptions
    .map((p) => Array.from(getProductOptionMeshesFabrics(p)))
    .flat(2);

  const uniqueArray = new Map();
  for (const element of result) {
    const key = element[0]; // Assuming the first item is used as the key for uniqueness
    const priority = element[2]; // Assuming the third item is the priority value
    if (!uniqueArray.has(key) || priority > uniqueArray.get(key)[2]) {
      uniqueArray.set(key, element);
    }
  }
  verbose > 0 && console.log("Mesh Fabric Color with priority", uniqueArray);

  return new Map(
    Array.from(uniqueArray.values()).map((curr) => [curr[0], curr[1]]),
  );
}

export function getAllProductOptionMeshesWithFabric(
  productOptions: Array<ProductOption>,
  productMeshFabric: ProductMeshFabric,
): Array<string> {
  return productOptions
    .filter((items) => hasProductOptionMeshes(items))
    .map((p) => getProductOptionMeshesWithFabric(p, productMeshFabric))
    .flat();
}

export function setNodeColor(node: Node, color: Color3 | undefined) {
  if (!color) {
    return;
  }
  if (node instanceof AbstractMesh) {
    const material = node.material as PBRMaterial;
    if (material) {
      material.albedoColor = color;
    }
    return node;
  } else if (typeof node.getChildMeshes === "function") {
    node.getChildMeshes().forEach((mesh) => {
      const material = mesh.material as PBRMaterial;
      if (material) {
        material.albedoColor = color;
      }
    });
    return node;
  }
}

export function getProductOptionDisabledMeshes(
  productOption: ProductOption,
): Array<string> {
  if (
    Array.isArray(productOption.data.disable_meshes) &&
    productOption.data.disable_meshes.length
  ) {
    return productOption.data.disable_meshes
      .filter((productOptionMesh) => typeof productOptionMesh.mesh === "string")
      .map((productOptionMesh) => productOptionMesh.mesh);
  }
  return [];
}

export function getAllProductOptionDisabledMeshes(
  productOptions: Array<ProductOption>,
): Array<string> {
  return productOptions.map((p) => getProductOptionDisabledMeshes(p)).flat();
}

export function getNodesByNameAndMaterial(
  scene: Scene,
  meshName: string,
  materialName: string,
) {
  const node = scene.getNodeByName(meshName);
  // node is a TransformNode with children
  if (
    node instanceof AbstractMesh &&
    node.material &&
    node.material.name === materialName
  ) {
    return [node];
  }
  // node is a TransformNode with children
  if (node && node.getChildMeshes()) {
    return node
      .getChildMeshes()
      .filter(
        (curr) =>
          curr instanceof AbstractMesh &&
          curr.material &&
          curr.material.name === materialName,
      );
  }
  //
  return [];
}

export function getNodeByName(scene: Scene, meshName: string) {
  return scene.getNodeByName(meshName);
}

export function getNodesByName(scene: Scene, meshNames: Array<string>) {
  // Imported glTF model node could be a babylonjs TransformNode with AbstractMesh children
  // or AbstractMesh.
  return meshNames.reduce((acc, meshName) => {
    const node = scene.getNodeByName(meshName);
    if (node) {
      acc.push(node);
    } else if (verbose > 0) {
      console.warn("Node not found:", meshName);
    }
    return acc;
  }, [] as Array<Node>);
}

export function showMeshesByName(
  scene: Scene | null,
  meshNames: Array<string>,
) {
  enableMeshesByName(scene, meshNames, true);
}

export function hideMeshesByName(
  scene: Scene | null,
  meshNames: Array<string>,
) {
  enableMeshesByName(scene, meshNames, false);
}

export function enableMeshesByName(
  scene: Scene | null,
  meshNames: Array<string>,
  value: boolean = true,
) {
  if (!scene) {
    return;
  }
  enableMeshes(getNodesByName(scene, meshNames), value);
}

export function enableMeshes(meshes: Array<Node>, value: boolean = true) {
  meshes.forEach((node) => {
    if (node instanceof AbstractMesh) {
      node.setEnabled(value);
    }
    node.getChildMeshes().forEach((mesh) => {
      mesh.setEnabled(value);
    });
  });
}

export function setMeshColors(
  scene: Scene | null,
  meshNames: Array<string>,
  color: Color3 | undefined,
) {
  if (!scene || !color) {
    return;
  }
  getNodesByName(scene, meshNames).forEach((node) => {
    setNodeColor(node, color);
  });
}

// meshNameAndColorName is a Map with mesh name as key and named color as value. <MeshName, ColorName>
// namedColors holds the named colors with name as key and color as value. <ColorName, Color3>
export function setMeshColorByName(
  scene: Scene | null,
  meshNameAndColorNames: Map<string, string>,
  namedColors: Map<string, Color3>,
) {
  if (!scene) {
    return;
  }

  meshNameAndColorNames.forEach((colorName, meshName) => {
    if (meshName.includes(":")) {
      // Handle mesh names with a material name as a filter "mesh_name:material_name"
      const [needle, materialName] = meshName.split(":");
      getNodesByNameAndMaterial(scene, needle, materialName).forEach((mesh) => {
        const namedColor = namedColors.get(colorName);
        namedColor && setNodeColor(mesh, namedColor);
      });
    } else {
      // Handle potentially comma-separated mesh names or single mesh names
      const meshNames = meshName.split(",");
      meshNames.forEach((individualMeshName) => {
        const node = getNodeByName(scene, individualMeshName.trim());
        if (node) {
          const namedColor = namedColors.get(colorName);
          namedColor && setNodeColor(node, namedColor);
        }
      });
    }
  });
}

// filter options to return id's of the parent and possible children
export function filterProductOptions(
  productOptions: ProductOption[] | null | undefined,
  product: Product | null | undefined,
): ProductOption[] | null {
  if (!productOptions || !product?.data.product_options) {
    return null;
  }

  const idToOptionMap: Map<string, ProductOption> = new Map(
    productOptions.map((option: ProductOption) => [option.id, option]),
  );

  let filteredOptions: ProductOption[] = [];

  for (const selected of product.data.product_options) {
    const parentId: string = selected.product_option.id;
    const parentOption: ProductOption | undefined = idToOptionMap.get(parentId);

    if (parentOption) {
      filteredOptions.push(parentOption);

      if (parentOption.data.children) {
        for (const child of parentOption.data.children) {
          const childId: string = child.option.id;
          const childOption: ProductOption | undefined =
            idToOptionMap.get(childId);
          if (childOption) {
            filteredOptions.push(childOption);
          }
        }
      }
    }
  }

  return filteredOptions;
}

export function getArrayDiff(a: Array<string>, b: Array<string>) {
  return a.filter((item) => !b.includes(item));
}

// based on babylonjs sandbox
// https://github.com/BabylonJS/Babylon.js/blob/master/packages/tools/viewer/src/managers/sceneManager.ts#L1012-L1034
// export function focusOnModel(model: ILoadedModel, camera:ArcRotateCamera) {
export function focusOnModel(model: ILoadedModel) {
  if (!model.rootMesh) {
    return;
  }
  const boundingInfo = model.rootMesh.getHierarchyBoundingVectors(true);
  const sizeVec = boundingInfo.max.subtract(boundingInfo.min);
  const halfSizeVec = sizeVec.scale(0.5);
  const center = boundingInfo.min.add(halfSizeVec);
  const sceneDiagonalLength = sizeVec.length();
  let upperRadiusLimit = undefined;
  let radius = sizeVec.length() * 1.15;
  // empty scene scenario!
  if (!isFinite(radius)) {
    radius = 1;
  }

  if (isFinite(sceneDiagonalLength)) {
    upperRadiusLimit = sceneDiagonalLength * 4;
  }
  return {
    center,
    upperRadiusLimit,
    boundingInfo,
    radius,
  };
}
// This function takes a single ProductOption and returns a flattened array
// containing the option and all its children, grandchildren, etc.
export const flattenProductOption = (
  option: ProductOption,
): ProductOption[] => {
  const flatList: ProductOption[] = [option];

  // Check that option.data and option.data.children are defined before proceeding.
  if (option.data?.children.length > 0) {
    for (const child of option.data.children) {
      if (child.option) {
        const childOption = child.option as ProductOption; // assuming children are of type ProductOption
        flatList.push(...flattenProductOption(childOption));
      }
    }
  }

  return flatList;
};
// This function takes an array of ProductOptions and returns a new array
// containing all options, including all children, grandchildren, etc.
export const flattenProductOptions = (
  options: ProductOption[],
): ProductOption[] => {
  let flatList: ProductOption[] = [];

  for (const option of options) {
    flatList.push(...flattenProductOption(option));
  }

  return flatList;
};

export function getPositionByMeshname(
  scene: Scene | null,
  meshNames: Array<string>,
) {
  if (!scene) {
    return [];
  }
  return meshNames.reduce((acc, meshName) => {
    const node = scene.getNodeByName(meshName);
    // if (node instanceof AbstractMesh) {
    //   node.renderOverlay = true;
    // }
    if (node && node instanceof TransformNode) {
      // acc.push(node.position);
      const boundingInfo = node.getHierarchyBoundingVectors(true);
      const sizeVec = boundingInfo.max.subtract(boundingInfo.min);
      const halfSizeVec = sizeVec.scale(0.5);
      const center = boundingInfo.min.add(halfSizeVec);
      acc.push(center);
    }
    return acc;
  }, [] as Array<Vector3>);
}

export function calcTextureImageUVscale(
  originalWidth: number,
  originalHeight: number,
  newWidth: number,
  newHeight: number,
) {
  // Calculate the aspect ratio of the original and new images
  const originalAspectRatio = originalWidth / originalHeight;
  const newAspectRatio = newWidth / newHeight;

  // Calculate the scale factors for uScale and vScale
  let uScaleFactor, vScaleFactor;

  if (originalAspectRatio > newAspectRatio) {
    // The new image is wider, so adjust uScale
    uScaleFactor = originalAspectRatio / newAspectRatio;
    vScaleFactor = 1; // No change in aspect ratio along the vertical axis
  } else {
    // The new image is taller, so adjust vScale
    uScaleFactor = 1; // No change in aspect ratio along the horizontal axis
    vScaleFactor = newAspectRatio / originalAspectRatio;
  }

  return [uScaleFactor, vScaleFactor];
}

export function setMeshLogoTexureUrl(
  mesh: AbstractMesh,
  url: string,
  scene: Scene,
) {
  const material = mesh.material as PBRMaterial;

  if (!material) {
    return;
  }

  // Check if url is updated
  if (material && material.albedoTexture) {
    const albedoTexture = material.albedoTexture as Texture;
    if (albedoTexture.url === url) {
      return;
    }
  }

  // Update texture url
  const albedoTexture = new Texture(url, scene, {
    invertY: false,
    samplingMode: Texture.TRILINEAR_SAMPLINGMODE,
    // samplingMode: Texture.BILINEAR_SAMPLINGMODE,
    // samplingMode: Texture.NEAREST_SAMPLINGMODE,
    onLoad: () => {
      // Scale and offset the logo texture
      const size = albedoTexture.getSize();
      const [uScale, vScale] = calcTextureImageUVscale(
        mesh.metadata.originalTextureWidth,
        mesh.metadata.originalTextureHeight,
        size.width,
        size.height,
      );
      albedoTexture.uScale = uScale;
      albedoTexture.vScale = vScale;
      albedoTexture.uOffset = (uScale - 1) * -0.5;
      albedoTexture.vOffset = (vScale - 1) * -0.5;
    },
  });
  albedoTexture.hasAlpha = true;
  albedoTexture.getAlphaFromRGB = false;
  albedoTexture.optimizeUVAllocation = true;
  albedoTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
  albedoTexture.wrapV = Texture.CLAMP_ADDRESSMODE;
  // Update material with logo texture
  material.albedoTexture = albedoTexture;
}

export const getProductOptionLogoMeshes = (productOption: ProductOption) =>
  productOption.data.meshes
    .filter((mesh) => mesh !== null && mesh.variant === "logo_mesh")
    .map((mesh) => mesh.mesh);

export function findNodesByName(scene: Scene, needle: string) {
  return scene.getNodes().filter((node) => node.name.includes(needle));
}
