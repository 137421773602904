import { Option, ProductOption } from "../../types";

// Prismic can have array of null objects
const filterValidOptions = (options: Array<Option>): Array<Option> =>
  options.filter((item) => item?.option?.id);

// Remove ProductOption and related ProductOptions
export const handleOptionsOnRemove = (
  option: ProductOption,
  selectedProductOptions: ProductOption[],
): ProductOption[] => {
  if (!selectedProductOptions) return [];
  // List of all ProductOptions ids to remove from selected productOptions
  const unselectProductOptions = [
    option.id,
    ...filterValidOptions(option.data.disable_exit_options).map(
      (item) => item.option.id,
    ),
  ];
  return selectedProductOptions.filter(
    (item) => !unselectProductOptions.includes(item.id),
  );
};

export const handleConflictOptions = (
  option: ProductOption,
  selectedProductOptions: ProductOption[],
  allProductOptions: ProductOption[],
): ProductOption[] => {
  if (!selectedProductOptions) return [];
  let newOptions = [
    ...selectedProductOptions.filter((curr) => curr.id !== option.id),
    option,
  ];
  // disable conflicting options
  filterValidOptions(option.data.disable_options).forEach(
    (disableOption: Option) => {
      newOptions = newOptions.filter(
        (item) => item?.id !== disableOption?.option.id,
      );
    },
  );
  // enable options that are locked by this option
  filterValidOptions(option.data.enable_options).forEach(
    (enableOption: Option) => {
      newOptions = [
        ...newOptions,
        allProductOptions.find(
          (option: ProductOption) => option.id === enableOption.option.id,
        ) as ProductOption,
      ];
    },
  );

  return newOptions;
};
