import AssetMananger from "./AssetManager";
import { Configurator } from "./Configurator/Configurator";

const AssetConfigurator = () => {
  return (
    <div className="flex flex-wrap flex-1 h-full">
      <div className="bg-gray flex flex-col w-full md:w-2/3">
        <AssetMananger />
      </div>
      <div className="flex flex-col p-8 justify-between w-full md:w-1/3 bg-white max-h-[calc(100vh-88px)]">
        <Configurator />
      </div>
    </div>
  );
};

export default AssetConfigurator;
