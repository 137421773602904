import { FC } from "react";
import { CartProduct, ConfigLoader } from "../../types";
import { Button } from "../ui/Button";
import { useAppDispatch } from "../../hooks";
import { useFetchProductConfigMutation } from "../../api/nest";
import {
  resetConfig,
  setConfigId,
  setConfigLoader,
  setIsEditing,
} from "../../store/reducers/configReducer";
import { useNavigate, useParams } from "react-router-dom";

interface EditDesignProps {
  cartItem: CartProduct;
}
export const EditDesign: FC<EditDesignProps> = ({ cartItem }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [fetchProductConfig] = useFetchProductConfigMutation();

  const editCartItem = async () => {
    const response = (await fetchProductConfig({ id: cartItem.id })) as {
      data: ConfigLoader;
    };
    if (response && response.data) {
      dispatch(resetConfig());
      dispatch(setIsEditing(true));
      dispatch(setConfigId(cartItem.id));
      dispatch(setConfigLoader(response.data));
      navigate(`/${projectId}`);
    }
  };

  return <Button onClick={editCartItem} link icon="edit" label="edit_design" />;
};
