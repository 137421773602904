import { FC, useEffect } from "react";
import { CartProduct } from "../../types";
import { Button } from "../ui/Button";
import { useDuplicateProductConfigMutation } from "../../api/nest";
import { useAppDispatch } from "../../hooks";
import { addToCart } from "../../store/reducers/cartReducer";

interface DuplicateDesignProps {
  cartItem: CartProduct;
}

export const DuplicateDesign: FC<DuplicateDesignProps> = ({ cartItem }) => {
  const dispatch = useAppDispatch();

  const [
    duplicateProductConfig,
    { data: productConfigResult, isSuccess },
  ] = useDuplicateProductConfigMutation();

  const duplicateCartItem = async () => {
    await duplicateProductConfig(cartItem.id);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(addToCart({
        ...cartItem,
        id: productConfigResult.id,
      }
));
    }
  }, [productConfigResult, isSuccess, dispatch, cartItem]);

  return (
    <Button
      onClick={duplicateCartItem}
      link
      icon="duplicate"
      label="duplicate"
    />
  );
};
