import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./404";
import Cart from "./components/Cart/Cart";
import Delivery from "./components/Checkout/Delivery";
import Review from "./components/Checkout/Review";
import AssetConfigurator from "./components/AssetConfigurator";
import { UserManager } from "oidc-client";
import { oidcConfig } from "./oidcConfig";
import Thanks from "./components/Checkout/Thanks";
import Orders from "./components/Orders";
import Projects from "./components/Project/Projects";
import ProductsTable from "./components/Products/ProductsTable";
import { CompleteYourLookImageGenerator } from "./components/Products/CompleteYourLookImageGenerator";
import { ProjectIdValidator } from "./ProjectIdValidator";

const userManager = new UserManager(oidcConfig);

export const router = createBrowserRouter([
  {
    path: "/:projectId?/:config?",
    element: <App userManager={userManager} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/signin-oidc",
    element: <App userManager={userManager} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/orders",
    element: <Orders userManager={userManager} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/projects",
    element: <Projects userManager={userManager} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/cart/:projectId",
    element: (
      <ProjectIdValidator>
        <Cart userManager={userManager} />
      </ProjectIdValidator>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/delivery/:projectId",
    element: (
      <ProjectIdValidator>
        <Delivery userManager={userManager} />
      </ProjectIdValidator>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/review/:projectId",
    element: (
      <ProjectIdValidator>
        <Review userManager={userManager} />
      </ProjectIdValidator>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/thanks",
    element: <Thanks userManager={userManager} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/configurator", // bypass splash/login route for testing
    element: <AssetConfigurator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/products-table",
    element: <ProductsTable />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/complete-your-look-image-generator",
    element: <CompleteYourLookImageGenerator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/error",
    errorElement: <ErrorPage />,
    element: <ErrorPage />,
  },
]);
