import { useRouteError } from "react-router-dom";

type Error = {
  statusText: string;
  message: string;
};

export default function ErrorPage() {
  const error = useRouteError() as Error;
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {error && (
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      )}

      <div className="m-5">
        <a className="underline" href="/">
          back to home page
        </a>
      </div>
    </div>
  );
}
