import React, { useEffect, useState } from "react";
import { ProductOption } from "../../../types";
import { logoNames } from "./config";
import { useAppSelector } from "../../../hooks";
import { selectProjectLogos } from "../../../store/reducers/configReducer";
import PrismicTranslation from "../../PrismicTranslation";

type ConfiguratorLogoSelectorProps = {
  productOption: ProductOption;
  onChange: (key: keyof ProductOption["data"], value: string) => void;
  isSelected?: boolean;
};

const ConfiguratorLogoSelector: React.FC<ConfiguratorLogoSelectorProps> = (
  props,
) => {
  const { productOption, onChange, isSelected } = props;
  const elementId = React.useId();
  const productLogos = useAppSelector(selectProjectLogos);
  const [availableLogos, setAvailableLogos] = useState<string[]>([]);

  // Get list of uploaded project logo names
  useEffect(() => {
    setAvailableLogos(productLogos.map((curr) => curr.logoName));
  }, [productLogos]);

  if (availableLogos.length === 0) {
    return (
      <div className="w-full flex gap-8">
        <div className="flex justify-between font-normal text-sm text-gray-500">
          <PrismicTranslation field="logo_not_uploaded" simple />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full flex gap-8">
        {logoNames.map((logo_name) => (
          <div
            className="flex items-center cursor-pointer"
            onClick={(e) => e.stopPropagation()}
            key={logo_name}
          >
            <input
              id={`${elementId}-${logo_name}`}
              name={`${elementId}_logo_name`}
              value={logo_name}
              type="radio"
              disabled={!availableLogos.includes(logo_name)}
              onClick={() => onChange("logo_option", logo_name)}
              defaultChecked={
                productOption?.data.logo_option === logo_name ||
                (!productOption?.data.logo_option && logo_name === "logo_1")
              }
              className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
            />
            <label
              htmlFor={`${elementId}-${logo_name}`}
              className="ml-3 block text-sm font-medium leading-6 text-gray-900"
            >
              <PrismicTranslation field={`project_${logo_name}`} simple />
            </label>
          </div>
        ))}
      </div>
      {isSelected &&
        !availableLogos.includes(productOption?.data.logo_option) && (
          <div className="flex justify-between font-normal text-sm text-red">
            <PrismicTranslation field="logo_not_uploaded" simple />
          </div>
        )}
    </>
  );
};

export { ConfiguratorLogoSelector };
