import { Fabric, FabricColor } from "../../../types";
import { FC, useCallback } from "react";
import { Icon } from "../../ui/Icon";
import {
  showDisabledColorOption,
  showIconOnDisabledColorOptions,
} from "../config";

interface ColorOptionProps {
  prefix: keyof FabricColor;
  option: Fabric;
  onClick: Function;
  defaultOption?: FabricColor;
  disabled?: boolean;
}

export const ColorOption: FC<ColorOptionProps> = ({
  prefix,
  option,
  onClick,
  defaultOption,
  disabled,
}: ColorOptionProps) => {
  const isWhite = option?.data?.web_format === "#FFFFFF";
  const isSelected = useCallback(
    (prefix: string, option: Fabric) => {
      const selectedOption =
        prefix === "main" ? defaultOption?.main : defaultOption?.contrast;
      return selectedOption?.id === option.id;
    },
    [defaultOption?.contrast, defaultOption?.main],
  );

  return (
    <div
      title={option?.data.name}
      onClick={() => onClick(prefix, option)}
      className={`cursor-pointer w-6 h-6 ${
        isWhite ? "border" : ""
      } rounded-full flex items-center justify-center 
      ${disabled === true && showDisabledColorOption ? "opacity-25" : ""} `}
      style={{
        backgroundColor: (option as Fabric)?.data.web_format,
      }}
    >
      {isSelected(prefix, option) && (
        <Icon
          className={`mt-1 ${isWhite ? "text-black" : "text-white"}`}
          icon="check"
        />
      )}

      {disabled === true && showIconOnDisabledColorOptions && (
        <Icon
          className={`mt-1 ${isWhite ? "text-black" : "text-white"}`}
          icon="close"
        />
      )}
    </div>
  );
};

export default ColorOption;
