import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./hooks";
import {
  selectCountryLanguage,
  setTranslation,
} from "./store/reducers/uiReducer";
import { useGetTranslationsQuery } from "./api/prismic";

export function TranslationManager({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const userCountryLanguage = useAppSelector(selectCountryLanguage);

  // Fetch translations at the start of the app
  const { isLoading: translationsLoading, data: translationResponse } =
    useGetTranslationsQuery({
      lang: userCountryLanguage,
    });

  // Put translation data into ui reducer
  useEffect(() => {
    const updateLang = true;
    if (
      !translationsLoading &&
      Array.isArray(translationResponse) &&
      translationResponse[0]?.data
    ) {
      if (updateLang) {
        dispatch(setTranslation(translationResponse[0].data));
      }
    }
  }, [translationsLoading, translationResponse, dispatch]);

  return <>{children}</>;
}
