import {
  getCartItemSizesPrice,
  getPrismicTranslation,
  totalCartProductAmount,
} from "../../utils";
import { Button } from "../ui/Button";
import React from "react";
import { CartProduct, Fabric, Supplier } from "../../types";
import { useAppSelector } from "../../hooks";
import { selectLanguage } from "../../store/reducers/uiReducer";
import PrismicTranslation from "../PrismicTranslation";

interface DeliveryItemProps {
  cartItem: CartProduct;
  fabricSupplier: Supplier | null;
}

const DeliveryItem = ({ cartItem, fabricSupplier }: DeliveryItemProps) => {
  const language = useAppSelector(selectLanguage);
  const [viewDetails, setViewDetails] = React.useState(false);

  return (
    <div className="flex flex-col gap-2 border-b border-gray p-2">
      <div className="flex justify-between">
        <span className="font-bold text-primary">
          {getPrismicTranslation(
            language,
            "name",
            cartItem?.productGroup?.data,
          )}{" "}
          ({totalCartProductAmount(cartItem)})
        </span>
        <span className="font-bold">
          {getCartItemSizesPrice(cartItem, fabricSupplier).toFixed(2)}€
        </span>
      </div>
      {viewDetails && (
        <div className="text-sm">
          <div>
            <div className="flex items-center gap-1.5">
              <strong className="shrink-0 self-start">
                <PrismicTranslation field={"main_color"} simple />:
              </strong>
              <span
                className="w-2.5 h-2.5 rounded-full border shrink-0"
                style={{
                  backgroundColor: (cartItem.fabric?.main as Fabric)?.data
                    .web_format,
                }}
              />
              <span>
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.fabric?.main?.data,
                )}
              </span>
            </div>
            <div className="flex items-center gap-1.5">
              <strong className="shrink-0 self-start">
                <PrismicTranslation field={"contrast_color"} simple />:
              </strong>
              <span
                className="w-2.5 h-2.5 rounded-full border shrink-0 "
                style={{
                  backgroundColor: (cartItem.fabric?.contrast as Fabric)?.data
                    .web_format,
                }}
              />
              <span>
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.fabric?.contrast?.data,
                )}
              </span>
            </div>
          </div>
          <div>
            <span className="font-bold">
              <PrismicTranslation field="product_options" simple />
            </span>
            <div className="text-sm text-text">
              {cartItem.productOptions?.map((productOption) => (
                <div key={productOption.id}>
                  {getPrismicTranslation(language, "name", productOption?.data)}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Button
        onClick={() => setViewDetails(!viewDetails)}
        link
        label={viewDetails ? "hide_details" : "view_details"}
      />
    </div>
  );
};

export default DeliveryItem;
