import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import { CartProduct, Size, SizingObject } from "../../types";

interface CartState {
  products: CartProduct[];
}

const initialState: CartState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<CartProduct>) => {
      state.products = [...state.products, action.payload];
    },
    updateProduct: (state, action: PayloadAction<{id: string, cartProduct: Partial<CartProduct>}>) => {
      const {id, cartProduct} = action.payload;
      // Find the index of the CartProduct to update
      const index = state.products.findIndex(item => item.id === id);

      // Ensure the CartProduct exists
      if (index !== -1) {
        const newCartProduct = {...state.products[index], ...cartProduct };
        // Update the CartProduct while keeping it at the same index
        state.products[index] = newCartProduct;
      }

    },
    removeFromCart: (state, action: PayloadAction<CartProduct>) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.filter(
          (product) => product.id !== action.payload.id,
        );
      }
    },
    updateAmount: (
      state,
      action: PayloadAction<{
        gender: string;
        id: string;
        size: any;
        amount: number;
      }>,
    ) => {
      const hasProduct = state.products?.find(
        (product) => product.id === action.payload.id,
      );
      if (hasProduct) {
        state.products = state.products?.map((product) => {
          if (product.id === action.payload.id) {
            const genderOption = product.amount?.find(
              (sizingObject: SizingObject) =>
                sizingObject.primary.translation_key === action.payload.gender,
            );
            const sizeOption = genderOption?.items.find(
              (optionSize: Size) => optionSize.size === action.payload.size,
            );
            sizeOption.amount = action.payload.amount;
          }
          return product;
        });
      }
    },
  },
});

export const { addToCart, removeFromCart, updateAmount, updateProduct } = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;
export const selectCartItems = (state: RootState) => state.cart.products;
export const selectLatestCartItem = (state: RootState) => {
  const cart = state.cart.products;
  return cart[cart.length - 1];
};

export default cartSlice.reducer;
