import React, { useEffect, useState } from "react";
import { UserManager } from "oidc-client";
import Layout from "./Layout";
import { selectUser } from "../store/reducers/userReducer";
import Title from "./ui/Title";
import Login from "./Login";
import { useAppSelector } from "../hooks";
import { Icon } from "./ui/Icon";
import { selectProjectId } from "../store/reducers/configReducer";
import { useNavigate } from "react-router-dom";
import { Button } from "./ui/Button";
import { toast } from "react-toastify";
import {
  useDuplicateProjectMutation,
  useFetchUserOrdersQuery,
} from "../api/nest";
import { Order } from "../types";
import PrismicTranslation from "./PrismicTranslation";

const Orders = ({ userManager }: { userManager: UserManager }) => {
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();
  const projectId = useAppSelector(selectProjectId);
  const isLoggedIn = !!user;
  const [loading, setLoading] = useState<boolean>(false);
  const { data: orders } = useFetchUserOrdersQuery(user?.id);
  const [
    duplicateProject,
    { data: duplicateProjectResult, isError, isSuccess },
  ] = useDuplicateProjectMutation();

  const reOrder = async (orderId: string) => {
    setLoading(true);
    const projectId = orders.find((order: Order) => order.id === orderId)
      ?.project?.id;

    if (!projectId) {
      setLoading(false);
      toast.error(<PrismicTranslation field="project_not_found" simple />);
      return;
    }
    await duplicateProject(projectId);
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        <PrismicTranslation field="error_duplicate_project" simple />,
      );
    }
    if (isSuccess) {
      navigate(`/${duplicateProjectResult.id}`);
    }
    setLoading(false);
  }, [isSuccess, isError, duplicateProjectResult, navigate]);

  return (
    <Layout userManager={userManager} background>
      {isLoggedIn ? (
        <div className="flex flex-wrap flex-1 h-full">
          <div className="bg-gray flex flex-col w-full pt-4 lg:px-10">
            <div
              onClick={() => navigate(`/${projectId || ""}`)}
              className="cursor-pointer w-full flex items-center gap-2 ml-2 underline text-text text-sm"
            >
              <Icon icon="back" size="small" />
              <span>
                <PrismicTranslation field="continue_shopping" simple />
              </span>
            </div>
            <Title size={1} label="my_orders" />
            {orders?.map((order: Order) => (
              <div className="w-full bg-white p-4 border border-gray">
                <Title
                  label={`${order.project?.name} | ${order.id}`}
                  className="text-text font-bold text-lg"
                />
                <span className="font-bold text-md">
                  {order.totalPrice?.toFixed(2)}€
                </span>
                <div className="flex gap-4">
                  {order.cart.map((cartItem) => (
                    <img
                      className="w-[56px] h-[56px] object-contain"
                      src={cartItem.productModelPicture || "/placeholder.png"}
                      alt={cartItem.product?.data?.name || "image"}
                    />
                  ))}
                </div>
                <div className="text-text mt-1 flex gap-4">
                  <Button
                    disabled={loading}
                    onClick={() => reOrder(order.id)}
                    label="reorder"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Login userManager={userManager} />
      )}
    </Layout>
  );
};

export default Orders;
