import React, { FC } from "react";
import { Icon } from "./Icon";
import CircularProgress from "./CircularProgress";
import PrismicTranslation from "../PrismicTranslation";

interface ButtonProps {
  className?: string;
  label: string;
  icon?: string;
  onClick?: () => void;
  full?: boolean;
  link?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  label,
  className,
  icon,
  onClick,
  full,
  link,
  disabled,
  secondary,
  loading,
}) => {
  const clickHandler = () => {
    if (disabled) return;
    onClick?.();
  };
  const secondaryColors =
    "bg-white hover:bg-primaryHover border-none text-black hover:text-white";
  const primaryColors =
    "bg-primary border-primary hover:bg-primaryHover text-white";
  return (
    <div
      onClick={clickHandler}
      className={`button font-bold h-12 gap-2 ${
        full ? "w-full py-8 " : "py-4 "
      } ${
        !link
          ? `px-8 py-4 border-b-2 rounded ${
              secondary ? secondaryColors : primaryColors
            }  justify-center`
          : "text-text hover:text-primaryHover underline py-1 h-8 text-sm"
      }
      ${disabled || loading ? "opacity-50 cursor-not-allowed" : ""}
      relative cursor-pointer inline-flex  items-center ${className}`}
    >
      {icon && <Icon icon={icon} />}
      <PrismicTranslation simple field={label} />
      {loading && <CircularProgress absolute />}
    </div>
  );
};
