import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectProductModelLoadingState } from "../../store/reducers/uiReducer";
import { ProductScene } from "./ProductScene";
import CircularProgress from "../ui/CircularProgress";
import ShareModal from "../ShareModal";
import { useEffect, useState } from "react";
import { selectConfig, setConfigId } from "../../store/reducers/configReducer";
import { useCreateOrUpdateProductConfigMutation } from "../../api/nest";
import { toast } from "react-toastify";
import PrismicTranslation from "../PrismicTranslation";
import DownloadProductFiche from "../Configurator/ProductFiche/Download";
import ShareIcon from "../ui/Icons/ShareIcon";
import ProductVariantSelector from "../Configurator/ProductVariantSelector";
import {ViewCart} from "./ViewCart";

const AssetManager = () => {
  const loadingState = useAppSelector((state) => {
    return selectProductModelLoadingState(state);
  });
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [
    createOrUpdateProductConfig,
    { data: createOrUpdateProductConfigResult, isError, isSuccess },
  ] = useCreateOrUpdateProductConfigMutation();

  const share = async () => {
    await createOrUpdateProductConfig({ config });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setConfigId(createOrUpdateProductConfigResult.id));
      setIsShareModalOpen(true);
    }
    if (isError) {
      toast.error(<PrismicTranslation field="create_config_error" simple />);
    }
  }, [createOrUpdateProductConfigResult, isError, isSuccess, dispatch]);

  return (
    <>
      <div className="flex flex-col flex-1 relative">
        <ProductScene />
        {loadingState === "pending" && (
          <div className="rounded-lg flex items-center gap-2 text-white p-1 align-middle absolute top-1/2 left-[calc(50%-40px)]">
            <CircularProgress />
          </div>
        )}
        <ProductVariantSelector />
        <ViewCart />
        <div
          onClick={share}
          className="cursor-pointer group border border-neutrals-300 rounded-full flex items-center bg-white p-2 align-middle absolute top-4 left-4 hover:bg-primary-200 hover:border-primary-300"
        >
          <ShareIcon className="fill-neutrals-400 group-hover:fill-[#708EC8] pointer-events-none" />
        </div>
        <DownloadProductFiche productConfig={config} />
      </div>
      <ShareModal setIsOpen={setIsShareModalOpen} isOpen={isShareModalOpen} />
    </>
  );
};

export default AssetManager;
