import { FC, useEffect, useState } from "react";
import Title from "../../ui/Title";
import { CartProduct, Product, ProductGroup } from "../../../types";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import CartProductCard from "./CartProductCard";

interface CompleteYourLookProps {
  setIsOpen?: (isOpen: boolean) => void;
  cartItem: CartProduct;
}

const CompleteYourLook: FC<CompleteYourLookProps> = ({ setIsOpen, cartItem }) => {
  const { data: productGroups } = useGetDocumentByTypeQuery({
    type: "product_group",
    lang: "en-us",
  });

  const { data: products } = useGetDocumentByTypeQuery({
    type: "product",
    lang: "en-us",
  });

  const [showCompleteYourLook, setShowCompleteYourLook] = useState(true);
  const [completeYourLookProductGroups, setCompleteYourLookProductGroups] =
    useState<ProductGroup[]>([]);

  // Only show CompleteYourLook if there are CompleteYourLook items available
  useEffect(() => {
    if (products && productGroups && cartItem) {
      const completeYourLookProductGroups = productGroups.filter(
        (productGroup: ProductGroup) =>
          productGroup.id !== cartItem.productGroup.id,
      );
      setCompleteYourLookProductGroups(completeYourLookProductGroups);
      const productsWithSameFabrics = completeYourLookProductGroups
        .map((productGroup: ProductGroup) => {
          const productGroupProducts = products.filter(
            (product: Product) => product.data.parent.id === productGroup.id,
          );
          const productWithSameFabrics = productGroupProducts.find(
            (product: Product) => {
              return product.data?.main_fabrics?.find(
                (fabric) => fabric.fabric?.id === cartItem?.fabric?.main?.id,
              );
            },
          );
          return productWithSameFabrics;
        })
        .filter((product: Product) => typeof product !== "undefined");
      setShowCompleteYourLook(productsWithSameFabrics.length > 0);
    }
  }, [productGroups, products, cartItem]);

  if (showCompleteYourLook) {
    return (
      <>
        <Title label="add_to_cart_modal_complete_look" />
        <div className="flex gap-3">
          {completeYourLookProductGroups.map((productGroup: ProductGroup) => (
            <CartProductCard
              key={productGroup.id}
              productGroup={productGroup}
              cartItem={cartItem}
              setIsOpen={setIsOpen}
            />
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default CompleteYourLook;
