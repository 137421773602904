import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks";
import Modal from "../../ui/Modal";
import Title from "../../ui/Title";
import { Button } from "../../ui/Button";
import { CartProduct, Fabric } from "../../../types";
import CompleteYourLook from "./CompleteYourLook";
import { getPrismicTranslation } from "../../../utils";
import { selectLanguage } from "../../../store/reducers/uiReducer";
import PrismicTranslation from "../../PrismicTranslation";

interface AddToCartModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  cartItem: CartProduct;
}

const AddToCartModal = ({
  isOpen,
  setIsOpen,
  cartItem,
}: AddToCartModalProps) => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const language = useAppSelector(selectLanguage);

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Title icon="check" label="add_to_cart_modal_title" className="mb-6" />
      {cartItem && (
        <div className="w-full bg-confirm p-3 border border-gray text-sm">
          <div className="flex items-center gap-6">
            <div className="shrink-0 w-24">
              <img
                className="object-contain"
                src={cartItem.productModelPicture || "/placeholder.png"}
                alt={getPrismicTranslation(
                  language,
                  "name",
                  cartItem.product?.data,
                )}
              />
            </div>
            <div className="flex flex-col flex-wrap gap-1.5">
              <div className="flex gap-1.5">
                <strong className="shrink-0">
                  <PrismicTranslation field={"product_fit"} simple />:
                </strong>
                {getPrismicTranslation(
                  language,
                  "name",
                  cartItem.product?.data,
                )}
              </div>
              <div className="flex items-center gap-1.5">
                <strong className="shrink-0 self-start">
                  <PrismicTranslation field={"main_color"} simple />:
                </strong>
                <span
                  className="w-2.5 h-2.5 rounded-full border shrink-0"
                  style={{
                    backgroundColor: (cartItem.fabric?.main as Fabric)?.data
                      .web_format,
                  }}
                />
                <span>
                  {getPrismicTranslation(
                    language,
                    "name",
                    cartItem.fabric?.main?.data,
                  )}
                </span>
              </div>
              <div className="flex items-center gap-1.5">
                <strong className="shrink-0 self-start">
                  <PrismicTranslation field={"contrast_color"} simple />:
                </strong>
                <span
                  className="w-2.5 h-2.5 rounded-full border shrink-0 "
                  style={{
                    backgroundColor: (cartItem.fabric?.contrast as Fabric)?.data
                      .web_format,
                  }}
                />
                <span>
                  {getPrismicTranslation(
                    language,
                    "name",
                    cartItem.fabric?.contrast?.data,
                  )}
                </span>
              </div>
              {cartItem.productOptions &&
                cartItem.productOptions.length > 0 && (
                  <div className="flex gap-1.5">
                    <strong>
                      <PrismicTranslation field={"product_options"} simple />:
                    </strong>
                    <span>
                      {cartItem.productOptions
                        .slice(0, 2) // Get the first 2 options
                        .map((option) =>
                          getPrismicTranslation(language, "name", option?.data),
                        )
                        .join(", ")}
                      {cartItem.productOptions.length > 2 &&
                        ` (+${cartItem.productOptions.length - 2})`}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap mt-4 gap-4">
        <Button
          icon="cart"
          onClick={() => navigate(`/cart/${projectId}`)}
          label="view_cart"
        />
        <div
          onClick={() => setIsOpen(false)}
          className="cursor-pointer flex items-center gap-2 m-2 underline text-text text-sm"
        >
          <span>
            <PrismicTranslation field="continue_shopping" simple />
          </span>
        </div>
      </div>
      <div className="mt-6">
        <CompleteYourLook setIsOpen={setIsOpen} cartItem={cartItem} />
      </div>
    </Modal>
  );
};

export default AddToCartModal;
