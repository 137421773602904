import React, { useCallback, useEffect } from "react";
import Title from "../../ui/Title";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  removeProjectLogo,
  selectConfigId,
  updateProjectLogo,
} from "../../../store/reducers/configReducer";
import {
  ProjectLogo,
  ProjectLogoName,
  ProjectLogoOption,
  ViewLoadingState,
} from "../../../types";
import { LogoOption } from "./LogoOption";
import { FileUpload } from "./FileDropZone";
import { Icon } from "../../ui/Icon";
import { useUploadProjectImageMutation } from "../../../api/nest";
import PrismicTranslation from "../../PrismicTranslation";
import TextArea from "../../ui/TextArea";
import {logoOptions} from "./config";

type ProjectLogoProps = {
  logoName: ProjectLogoName;
  data: ProjectLogo | undefined;
};

export type ProjectLogoEdit = Omit<ProjectLogo, "logoName">;

const defaultProjectLogo = (): ProjectLogoEdit => {
  return {
    logoOption: "embroidered",
    comment: "",
    texture_url: "",
    thumbnail_url: "",
    asset_url: "",
  };
};

const ProjectLogoItem: React.FC<ProjectLogoProps> = (props) => {
  const componentId = React.useId();
  const dispatch = useAppDispatch();
  const configId = useAppSelector((state) => selectConfigId(state));
  // Editable ProjectLogo data
  const [localData, setLocalData] = React.useState<
    ProjectLogoEdit | undefined
  >();
  const [comment, setComment] = React.useState('');

  const [loadingState, setLoadingState] =
    React.useState<ViewLoadingState>("idle");

  const [statusMessage, setStatusMessage] = React.useState<string>("");

  const [
    uploadProjectImage,
    { data: uploadProjectImageResult, isSuccess, isError },
  ] = useUploadProjectImageMutation();

  const removeProductLogo = () => {
    if (props.data) {
      dispatch(removeProjectLogo(props.data));
    }
  };

  // 'localData' is undefined unitl parent component passes the data from the redux
  // store. After setting localData all subsequent changes from the redux store
  // are ignored.
  React.useEffect(() => {
    // props.data is set, set localData once
    if (props.data && typeof localData === "undefined") {
      setLocalData({
        ...defaultProjectLogo(),
        ...props.data,
      });
    } else if (!props.data && typeof localData !== "undefined") {
      // props.data is reset, also reset localData
      setLocalData(undefined);
    }
  }, [props.data, localData]);

  // Html element change event
  const handleOptionChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!localData) {
      return;
    }
    setLocalData({
      ...localData,
      logoOption: evt.target.value as ProjectLogoOption,
    });
  };

  const startFileUpload = useCallback(
    async (file: File, configId?: string) => {
      try {
        setLoadingState("pending");
        await uploadProjectImage(file);
      } catch (error: any) {
        console.error("Error uploading image:", error);
        setLoadingState("failed");
        setStatusMessage("Error uploading image");
      }
    },
    [uploadProjectImage],
  );

  useEffect(() => {
    if (isError && loadingState !== "failed") {
      setLoadingState("failed");
      setStatusMessage("Error uploading image");
    }
    if (isSuccess && loadingState !== "succeeded") {
      const { texture_url, thumbnail_url, asset_url, id } =
        uploadProjectImageResult;
      if (id) {
        setLocalData({
          ...(localData || defaultProjectLogo()),
          texture_url: `${process.env.REACT_APP_API_PATH}${texture_url}`,
          thumbnail_url: `${process.env.REACT_APP_API_PATH}${thumbnail_url}`,
          asset_url: `${process.env.REACT_APP_API_PATH}${asset_url}`,
          id,
        });
        setLoadingState("succeeded");
      }
    }
  }, [localData, uploadProjectImageResult, isSuccess, isError, loadingState]);

  useEffect(() => {
    if (!props.logoName || !localData) {
      return;
    }
    dispatch(updateProjectLogo({ ...localData, comment, logoName: props.logoName }));
  }, [localData, comment, props.logoName, dispatch]);

  return (
    <div className="flex flex-wrap gap-1">
      <div className="w-full mb-2">
        <Title secondary size={3} label={`project_${props.logoName}`} />
        <div className="flex flex-wrap gap-1">
          <FileUpload
            onFileSelected={(f) => startFileUpload(f, configId)}
            loadingState={loadingState}
            statusMessage={statusMessage}
          />
          {localData?.thumbnail_url && (
            <div className="relative inline-block border-2 border-gray">
              <img
                src={localData?.thumbnail_url}
                alt="product logo thumbnail"
                className="w-28"
              />

              <div
                onClick={removeProductLogo}
                className="cursor-pointer text-text rounded-full bg-primary p-1 absolute -top-3 -right-3 text-white"
              >
                <Icon
                  onClick={removeProductLogo}
                  icon="close"
                  size={12}
                  className="scale-75"
                />
              </div>
            </div>
          )}

          {localData && (
            <>
              {/** EMBROIDERED / PRINTED */}
              <div className="w-full flex gap-8">
                {" "}
                {logoOptions.map((option) => (
                  <LogoOption
                    key={option}
                    data={localData}
                    name={props.logoName}
                    value={option}
                    onChange={handleOptionChange}
                  />
                ))}
              </div>
              {/** COMMENT */}
              <div className="flex flex-col w-full">
                <div>
                  <label htmlFor={`${componentId}-comment`}>
                    <PrismicTranslation field="additional_notes" simple />
                  </label>
                </div>
                <div className="w-full">
                  <TextArea
                    className="w-full resize-none border-gray border border-1"
                    id={`${componentId}-comment`}
                    name="comment"
                    defaultValue={localData.comment}
                    onDeferChange={setComment}
                    maxLength={256}
                    deferTimeout={1500}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ProjectLogoItem };
