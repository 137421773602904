import { useCallback } from "react";
import {
  selectHasProduct,
  selectAvailableProductVariants,
  selectProductVariant,
  setProductVariant,
} from "../../store/reducers/configReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ProductVariant } from "../../types";
import { Switch } from "../ui/Switch";

const ProductVariantSelector = () => {
  const dispatch = useAppDispatch();
  const availableProductVariants = useAppSelector<ProductVariant[]>(
    selectAvailableProductVariants,
  );
  const selectedProductVariant = useAppSelector(selectProductVariant);
  const hasProduct = useAppSelector<boolean>(selectHasProduct);

  const onSelect = useCallback(
    (value: string) => {
      const productVariant = value as ProductVariant;
      if (
        availableProductVariants.includes(productVariant) &&
        productVariant !== selectedProductVariant
      ) {
        dispatch(setProductVariant(productVariant));
      }
    },
    [selectedProductVariant, availableProductVariants],
  );

  if (
    !hasProduct ||
    !availableProductVariants ||
    !selectedProductVariant ||
    availableProductVariants.length < 2
  ) {
    return null;
  }

  return (
    <Switch
      onChange={onSelect}
      labelA="female"
      labelB="male"
      valueA="female"
      valueB="male"
      initial={selectedProductVariant}
    />
  );
};

export default ProductVariantSelector;
