import React from "react";
import { LookImageData, lookImageUrl } from ".";

const buttonClasses =
  "button font-bold py-4 px-8 rounded bg-neutrals-400 border-neutral-300 hover:bg-primaryHover text-white justify-center disabled:opacity-50";

interface ImageItemProps {
  name: string;
  item: LookImageData;
}
const ImageItem: React.FC<ImageItemProps> = (props: ImageItemProps) => {
  const lines = props.name.split("-");
  return (
    <div className="m-2 p-2 bg-neutrals-100">
      <div>
        <img
          src={`${process.env.PUBLIC_URL}/look-images/${lookImageUrl(
            props.name,
          )}`}
          aria-hidden
          alt="product look-image on server"
        />
      </div>
      <small>
        {lines.map((v, k) => (
          <div key={`${k}${v}`}>{v}</div>
        ))}
      </small>
    </div>
  );
};

interface ShowExistingImagesProps {
  items: Map<string, LookImageData>;
}
export const ShowExistingImages: React.FC<ShowExistingImagesProps> = ({
  items,
}) => {
  const [showItems, setShowItems] = React.useState(false);

  return (
    <div>
      <button
        onClick={() => {
          setShowItems(!showItems);
        }}
        disabled={items.size === 0}
        className={buttonClasses}
      >
        Show existing look images.
      </button>
      <div className="flex flex-wrap">
        {showItems &&
          Array.from(items.entries()).map(([key, value]) => (
            <ImageItem key={key} name={key} item={value} />
          ))}
      </div>
    </div>
  );
};

export default ShowExistingImages;
