import React, { useState, useEffect, useRef, useCallback } from "react";
import { LookImageData } from ".";

interface LoopDeferProps {
  items: Map<string, LookImageData>;
  // Called when starting on item
  onTick: (
    key: string,
    value: any,
    currentIndex: number,
    totalItems: number,
  ) => void;
  // Called with the completed item
  onComplete?: (key: string) => void;
  marker: any; // The property to track
}

const buttonClasses =
  "button font-bold py-4 px-8 rounded bg-primary border-primary hover:bg-primaryHover text-white justify-center disabled:opacity-50";

// "Defer" like loop that will call onTick if "marker" does not change for n seconds.
// Loops over "items" until the end is reached.
export const LoopDefer: React.FC<LoopDeferProps> = ({
  items,
  onTick,
  onComplete,
  marker,
}) => {
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const dataKeys: string[] = Array.from(items.keys());
  const currentIndex = useRef<number>(0);
  const currentKey = useRef<string>(""); // dataKeys of the currentIndex
  const intervalRef = useRef<number | null>(null);
  const showAllButtons = false;

  const start = () => {
    if (!isRunning && dataKeys.length > 0) {
      setIsRunning(true);
      const key = dataKeys[currentIndex.current];
      currentKey.current = key;
      onTick(key, items.get(key), currentIndex.current, dataKeys.length);
      nextItem();
    }
  };

  const stop = useCallback(() => {
    setIsRunning(false);
    if (intervalRef.current !== null) {
      clearTimeout(intervalRef.current);
    }
  }, []);

  const next = () => {
    if (!isRunning) {
      nextItem();
    }
  };

  const reset = useCallback(() => {
    stop();
    currentIndex.current = 0;
    const key = dataKeys[currentIndex.current];
    currentKey.current = key;
    onTick(key, items.get(key), currentIndex.current, dataKeys.length);
  }, [currentIndex, items, dataKeys, onTick, stop]);

  const nextItem = React.useCallback(() => {
    if (currentIndex.current >= dataKeys.length) {
      setIsRunning(false);
      currentIndex.current = 0;
      currentKey.current = dataKeys[0];
      if (onComplete) {
        onComplete(currentKey.current);
      }
      return;
    }
    const key = dataKeys[currentIndex.current];
    currentKey.current = key;
    onTick(key, items.get(key), currentIndex.current, dataKeys.length);
    currentIndex.current += 1;
  }, [currentIndex, items, dataKeys, onTick, onComplete]);

  useEffect(() => {
    if (!isRunning && intervalRef.current !== null) {
      clearTimeout(intervalRef.current);
    }
  }, [isRunning]);

  // Track changes to the marker property
  useEffect(() => {
    if (!isRunning) {
      return;
    }
    const timeout = window.setTimeout(() => {
      if (isRunning) {
        if (onComplete) {
          onComplete(currentKey.current);
        }
        nextItem();
      }
    }, 1500); // n seconds delay

    return () => {
      clearTimeout(timeout);
    };
  }, [isRunning, nextItem, marker, onComplete]);

  return (
    <div>
      <div className="flex gap-1">
        <button className={buttonClasses} onClick={start} disabled={isRunning}>
          Start
        </button>
        <button className={buttonClasses} onClick={stop} disabled={!isRunning}>
          Stop
        </button>
        {showAllButtons && (
          <>
            {" "}
            <button
              className={buttonClasses}
              onClick={next}
              disabled={isRunning}
            >
              Next
            </button>
            <button
              className={buttonClasses}
              onClick={reset}
              disabled={isRunning}
            >
              Reset
            </button>
          </>
        )}
      </div>
      <div>Current index: {currentIndex.current}</div>
      <div>Current Item: {dataKeys[currentIndex.current]}</div>
    </div>
  );
};

export default LoopDefer;
