import React, { useState } from "react";

const buttonClasses =
  "button font-bold py-4 px-8 rounded bg-neutrals-400 border-neutral-300 hover:bg-primaryHover text-white justify-center disabled:opacity-50";

interface FetchExistingImagesProps {
  onComplete?: (data: string[]) => void;
}

export const FetchExistingImages: React.FC<FetchExistingImagesProps> = ({
  onComplete,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const timestamp = new Date().getTime();
      const response = await fetch(`/look-images-api/list?ts=${timestamp}`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      setData(result);
      setLoading(false);
      if (onComplete) {
        onComplete(result);
      }
    } catch (error) {
      setError((error as Error).message);
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={loading} className={buttonClasses}>
        Remove existing look image files from the list.
        {loading ? "Loading..." : ""}
      </button>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {data && <pre>Existing look images file count: {data.length}</pre>}
    </div>
  );
};

export default FetchExistingImages;
