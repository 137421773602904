import {
  CartProduct,
  Fabric,
  ObjectWithProductOption,
  Product,
  ProductGroup,
  ProductOption,
} from "../../../types";
import React, { useEffect } from "react";
import { Button } from "../../ui/Button";
import { addToCart } from "../../../store/reducers/cartReducer";
import {
  resetConfig,
  resetConfigId,
  selectProjectId,
  setCompleteYourLookFabrics,
  setConfigId,
  setSelectedFabric,
  setSelectedProduct,
  setSelectedProductGroup,
} from "../../../store/reducers/configReducer";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import { useCreateOrUpdateProductConfigMutation } from "../../../api/nest";
import { toast } from "react-toastify";
import { getPrismicTranslation } from "../../../utils";
import { selectLanguage } from "../../../store/reducers/uiReducer";
import PrismicTranslation from "../../PrismicTranslation";
type Props = {
  cartItem: CartProduct;
  productGroup: ProductGroup;
  setIsOpen?: (isOpen: boolean) => void;
};

const hasSameContrastFabric = (product: Product, cartItem: CartProduct) =>
  product?.data?.contrast_fabrics?.some(
    (fabric) => fabric.fabric?.id === cartItem?.fabric?.contrast?.id,
  );

const CartProductCard = ({ cartItem, setIsOpen, productGroup }: Props) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const projectId = useAppSelector((state) => selectProjectId(state));
  const { data: products } = useGetDocumentByTypeQuery({
    type: "product",
    lang: "en-us",
  });
  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const [
    _createOrUpdateProductConfig,
    { data: createOrUpdateProductConfigResult, isError, isSuccess },
  ] = useCreateOrUpdateProductConfigMutation();

  const productGroupProducts = React.useMemo(() => {
    if (products && productGroup) {
      return (
        products.filter(
          (product: Product) => product.data.parent.id === productGroup.id,
        ) || []
      );
    }
    return [];
  }, [products, productGroup]);

  const productWithSameFabrics = productGroupProducts?.find(
    (product: Product) => {
      return product.data?.main_fabrics?.find(
        (fabric) => fabric.fabric?.id === cartItem?.fabric?.main?.id,
      );
    },
  );

  const defaultProduct =
    productWithSameFabrics || (productGroupProducts?.[0] as Product);

  const defaultProductOptions = productOptions?.filter(
    (option: ProductOption) =>
      defaultProduct?.data?.product_options.find(
        (productOption: ObjectWithProductOption) =>
          productOption.product_option.id === option.id,
      ),
  );

  const enabledDefaultProductOptions = defaultProductOptions?.filter(
    (option: ProductOption) => option.data?.default,
  );

  const productConfig = React.useMemo(
    () => ({
      projectId,
      productGroup: productGroup,
      productOptions: enabledDefaultProductOptions,
      product: defaultProduct,
      fabric: {
        main: cartItem?.fabric?.main,
        contrast: hasSameContrastFabric(defaultProduct, cartItem)
          ? cartItem?.fabric?.contrast
          : undefined,
      },
      projectLogos: [],
    }),
    [
      productGroup,
      enabledDefaultProductOptions,
      defaultProduct,
      cartItem,
      projectId,
    ],
  );

  const productConfigLookImageUrl = React.useMemo(() => {
    if (
      defaultProduct &&
      cartItem?.fabric?.main &&
      cartItem?.fabric?.contrast &&
      hasSameContrastFabric(defaultProduct, cartItem)
    ) {
      return `${defaultProduct.id}-${cartItem.fabric.main.id}-${cartItem.fabric.contrast.id}.png`;
    }
    return false;
  }, [defaultProduct, cartItem]);

  const configureExtraProduct = () => {
    const {product, productGroup, fabric: {main, contrast}} = productConfig;
    if (product && productGroup && main && contrast) {
      dispatch(resetConfigId());
      dispatch(resetConfig());
      dispatch(setSelectedProductGroup(productConfig.productGroup));
      dispatch(setSelectedProduct(productConfig.product));
      // @TODO Replace "setCompleteYourLookFabrics" with something like "setIgnoreFabricDefaults" to fabric selector component does not apply default fabrics.
      dispatch(setCompleteYourLookFabrics({ main, contrast }));
      dispatch(setSelectedFabric({ fabric: main, colorOption: "main" }));
      dispatch(setSelectedFabric({ fabric: contrast, colorOption: "contrast" }));
    }
    //close modal if open
    setIsOpen && setIsOpen(false);
  };

  useEffect(() => {
    if (isError) {
      toast.error(<PrismicTranslation field={"create_config_error"} simple />);
    }
    if (isSuccess) {
      const { id } = createOrUpdateProductConfigResult;
      dispatch(setConfigId(id));
      dispatch(
        addToCart({
          ...productConfig,
          ...{
            id,
            amount: defaultProduct.data?.body,
          },
        }),
      );
    }
  }, [
    isError,
    isSuccess,
    createOrUpdateProductConfigResult,
    defaultProduct,
    dispatch,
    productConfig,
  ]);

  if (productWithSameFabrics && defaultProduct) {
    return (
      <div
        key={defaultProduct?.id}
        className="flex flex-col w-full sm:w-1/2 gap-2 cursor-pointer text-sm"
      >
        {productConfigLookImageUrl && (
          <img
            src={`${process.env.PUBLIC_URL}/look-images/${productConfigLookImageUrl}`}
            alt={getPrismicTranslation(language, "name", defaultProduct.data)}
            width={100}
            height={100}
          />
        )}
        <span className="text-primary font-bold">
          {getPrismicTranslation(language, "name", productGroup?.data)}
        </span>
        {defaultProduct.data.price && (
          <span className="font-bold">{defaultProduct.data.price}€</span>
        )}
        <div className="flex flex-wrap items-center gap-2">
          <span className="font-bold">
            <PrismicTranslation field={"product_fit"} simple />:
          </span>
          <span>
            {getPrismicTranslation(language, "name", defaultProduct?.data)}
          </span>
        </div>
        <div className="flex flex-wrap items-center gap-2">
          <span className="font-bold">
            <PrismicTranslation field={"main_color"} simple />:
          </span>
          <div
            title={getPrismicTranslation(
              language,
              "name",
              cartItem?.fabric?.main?.data,
            )}
            className="w-2.5 h-2.5 rounded-full border"
            style={{
              backgroundColor: (cartItem?.fabric?.main as Fabric)?.data
                .web_format,
            }}
          />
          <span>
            {getPrismicTranslation(
              language,
              "name",
              cartItem?.fabric?.main?.data,
            )}
          </span>
        </div>
        {hasSameContrastFabric(defaultProduct, cartItem) && (
          <div className="flex flex-wrap items-center gap-2">
            <span className="font-bold">
              <PrismicTranslation field={"contrast_color"} simple />:
            </span>
            <div
              title={getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.contrast?.data,
              )}
              className="w-2.5 h-2.5 rounded-full border"
              style={{
                backgroundColor: (cartItem?.fabric?.contrast as Fabric)?.data
                  .web_format,
              }}
            />
            <span>
              {getPrismicTranslation(
                language,
                "name",
                cartItem?.fabric?.contrast?.data,
              )}
            </span>
          </div>
        )}
        <Button onClick={configureExtraProduct} icon="cart" label="configure" />
      </div>
    );
  }
  return null;
};

export default CartProductCard;
