import { FC, useEffect, useState } from "react";
import {
  useLazyFetchProjectQuery,
  useUpdateProjectMutation,
} from "../../api/nest";
import { Input } from "../ui/Input";
import { useDebounceEffect } from "ahooks";

export type EditTitleProps = {
  projectId: string;
};

export const EditTitle: FC<EditTitleProps> = ({ projectId }) => {
  // fetch existing project query
  const [fetchProjectQuery, { data: project }] = useLazyFetchProjectQuery();
  const [projectName, setProjectName] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false); // prevents initial debounceEffect
  const [hasError, setHasError] = useState<boolean>(false);
  const [updateProjectMutation] = useUpdateProjectMutation();

  useEffect(() => {
    if (projectId && !project) {
      fetchProjectQuery(projectId);
    }
    if (project?.name) {
      setProjectName(project.name || "");
    }
  }, [projectId, project, fetchProjectQuery]);

  // Debounce api update
  useDebounceEffect(
    () => {
      if (
        isDirty &&
        projectId &&
        !hasError &&
        typeof projectName === "string" &&
        projectName.trim().length > 0
      ) {
        updateProjectMutation({
          project: { id: projectId, name: projectName.trim() },
        });
      }
    },
    [projectName, hasError, projectId, isDirty],
    { wait: 500 },
  );

  // Update project name
  const handleChange = (value: string) => {
    const projectName = value.trimStart();
    setProjectName(projectName);
    setIsDirty(true);
    if (projectName) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  if (!project) {
    return null;
  }

  return (
    <div>
      <Input
        required
        isError={hasError}
        errorLabel="validation_projectname"
        alternateLabel
        value={projectName}
        onChange={(e) => handleChange(e.target.value)}
        label="create_project_name"
        placeholder=""
      />
    </div>
  );
};
