import { useParams, useLocation, Navigate } from "react-router-dom";
import { isValidProjectIdFormat } from "./utils";

const navigateToErrorPage = false;
export const ProjectIdValidator = ({ children }: { children: JSX.Element }) => {
  const { projectId } = useParams();
  const location = useLocation();
  const params = useParams();

  // Check if projectId is present and valid
  if (isValidProjectIdFormat(projectId)) {
    return children; // Render children if projectId is valid
  }

  console.log("Invalid projectId.", location, params);
  if (navigateToErrorPage) {
    return <Navigate to="/error" replace />; // Redirect to an error page if invalid
  }
  return children; // Render children if projectId is valid
};
