import Layout from "./components/Layout";
import { useAppSelector } from "./hooks";
import AssetConfigurator from "./components/AssetConfigurator";
import { UserManager } from "oidc-client";
import { selectProjectSetupComplete } from "./store/reducers/uiReducer";
import AppSetup from "./AppSetup";

function App({ userManager }: { userManager: UserManager }) {
  const projectSetupComplete = useAppSelector(selectProjectSetupComplete);

  if (projectSetupComplete) {
    return (
      <Layout userManager={userManager} background={false}>
        <AssetConfigurator />
      </Layout>
    );
  }

  return (
    <AppSetup userManager={userManager}/>
  );
}

export default App;
