import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectConfigId,
  selectIsConfigInCart,
  selectProductModelPicture,
} from "../../store/reducers/configReducer";
import { useThrottleEffect } from "ahooks";
import ScreenshotPoc from "../AssetManager/ScreenshotPoc";
import { useUpdateProductConfigProductImageMutation } from "../../api/nest";

// Commit product model picture changes to the backend. This frees the main
// ProductConfig update call (useSetProjectStatusMutation) from waiting on the
// image updates.
export const ProductModelImageUpload = () => {
  const productModelPicture = useAppSelector(selectProductModelPicture);
  const id = useAppSelector(selectConfigId);
  const isConfigInCart = useAppSelector(selectIsConfigInCart);
  const dispatch = useAppDispatch();
  const showModelPicture = false;

  const [updateProductConfigProductImage] =
    useUpdateProductConfigProductImageMutation();

  useThrottleEffect(
    () => {
      if (!productModelPicture || !id) {
        return;
      }
      // If ProductConfig is in cart do not post updates until feature changes 
      // are pushed back to the cart.
      if (isConfigInCart) {
        return;
      }
      updateProductConfigProductImage({
        config: {
          id,
          productModelPicture,
        },
      });
    },
    [id, productModelPicture, dispatch, isConfigInCart],
    { wait: 500 },
  );

  if (showModelPicture) {
    return <ScreenshotPoc base64Image={productModelPicture} />;
  }
  return null;
};
