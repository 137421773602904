import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  selectConfigId,
  selectIsConfigInCart,
} from "../../store/reducers/configReducer";
import { useNavigate, useParams } from "react-router-dom";
import PrismicTranslation from "../PrismicTranslation";
import { selectCartItems } from "../../store/reducers/cartReducer";

import { Icon } from "../ui/Icon";
import { setPreviousProductConfigId } from "../../store/reducers/uiReducer";

interface ViewCartProps {}

export const ViewCart: FC<ViewCartProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isConfigInCart = useAppSelector(selectIsConfigInCart);
  const configId = useAppSelector(selectConfigId);
  const cartItems = useAppSelector(selectCartItems);
  const { projectId } = useParams();
  const hasCartItems = cartItems.length > 0;

  const onClick = useCallback(() => {
    if (!isConfigInCart && configId) {
      dispatch(setPreviousProductConfigId(configId));
    }
    navigate(`/cart/${projectId}`);
  }, [configId, dispatch, isConfigInCart, navigate, projectId]);

  if (hasCartItems) {
    return (
      <div
        onClick={onClick}
        className="cursor-pointer rounded-full flex items-center gap-2 bg-white p-2 align-middle absolute bottom-4 left-[calc(50%-40px)]"
      >
        <Icon icon="cart" />
        <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red border-2 border-white rounded-full -top-2 left-4">
          {cartItems.length}
        </div>
        <PrismicTranslation field="view_cart" simple />
      </div>
    );
  }

  return null;
};
