import Title from "../ui/Title";
import DeliveryItem from "./DeliveryItem";
import { selectCartItems } from "../../store/reducers/cartReducer";
import { useAppSelector } from "../../hooks";
import OrderSummary from "./OrderSummary";
import { selectFabricSupplier } from "../../store/reducers/configReducer";

const Summary = () => {
  const cartItems = useAppSelector(selectCartItems);
  const fabricSupplier = useAppSelector(selectFabricSupplier);

  return (
    <div className="lg:h-[calc(100vh-300px)] lg:overflow-scroll-y">
      <Title label="your_order" />
      {cartItems.map((cartItem) => (
        <DeliveryItem
          cartItem={cartItem}
          key={cartItem.id}
          fabricSupplier={fabricSupplier}
        />
      ))}
      <OrderSummary showWarning fabricSupplier={fabricSupplier} />
    </div>
  );
};

export default Summary;
