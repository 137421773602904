import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../index";
import {
  BaseColorOption,
  CartProduct,
  CompleteYourLookFabrics,
  ConfigLoader,
  Fabric,
  ObjectWithId,
  Product,
  ProductGroup,
  ProductOption,
  ProductVariant,
  ProjectLogo,
  ProjectLogoName,
} from "../../types";
import {
  calcProductOptionVariantPrice,
  isProductOptionWithLogo,
} from "../../utils";
import { defaultProductVariant } from "../../components/Configurator/config";

interface ConfigState {
  isEditing?: boolean;
  id?: string; // id of the ProductConfig
  projectId?: string;
  configLoader?: ConfigLoader;
  productGroup?: ProductGroup;
  product?: Product;
  fabric?: {
    main?: Fabric;
    contrast?: Fabric;
  };
  fabricSupplier: ObjectWithId | null;
  productOptions: ProductOption[];
  productModelPicture: string | undefined; // base64 png
  projectLogos: ProjectLogo[];
  productVariant: ProductVariant;
  availableVariants: ProductVariant[];
  completeYourLookFabrics: CompleteYourLookFabrics | null;
  productInitDone: boolean;
}

const initialState: ConfigState = {
  isEditing: false,
  id: undefined,
  projectId: "",
  productGroup: undefined,
  product: undefined,
  fabric: undefined,
  fabricSupplier: null,
  productOptions: [],
  productModelPicture: undefined,
  projectLogos: [],
  productVariant: defaultProductVariant,
  availableVariants: [defaultProductVariant],
  completeYourLookFabrics: null,
  productInitDone: false, // True if product configuration is loaded and set by the react components. Set to  false if product config is reset. 
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    // Use before loading new "configLoader" data
    resetConfig: (state) => {
      state.productGroup = undefined;
      state.product = undefined;
      state.fabric = undefined;
      state.fabricSupplier = null;
      state.productOptions =  [];
      state.completeYourLookFabrics = null;
      state.productInitDone = false;
    },
    setConfigLoader: (state, action: PayloadAction<ConfigLoader>) => {
      state.configLoader = action.payload;
      if (action.payload.projectLogos) {
        state.projectLogos = action.payload.projectLogos.map(
          (projectLogo, idx) => ({
            ...projectLogo,
            texture_url: `${process.env.REACT_APP_API_PATH}${projectLogo.texture_url}`,
            thumbnail_url: `${process.env.REACT_APP_API_PATH}${projectLogo.thumbnail_url}`,
            asset_url: `${process.env.REACT_APP_API_PATH}${projectLogo.asset_url}`,
            logoName:
              projectLogo.logoName || (`logo_${idx + 1}` as ProjectLogoName),
          }),
        );
      }
      if (action.payload.productVariant) {
        state.productVariant = action.payload.productVariant;
      }
    },
    resetConfigId: (state) => {
      state.id = undefined;
      state.productInitDone = false;
    },
    setConfigId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
    setProjectId: (state, action: PayloadAction<string | undefined>) => {
      state.projectId = action.payload;
    },
    setSelectedProductGroup: (
      state,
      action: PayloadAction<ProductGroup | undefined>,
    ) => {
      state.productGroup = action.payload;
      state.productOptions = [];
      state.fabric = undefined;
      state.fabricSupplier = null;
      state.isEditing = false;
    },
    setSelectedProduct: (state, action: PayloadAction<Product | undefined>) => {
      state.product = action.payload;
      state.productOptions = [];
      state.productModelPicture = undefined;
      state.fabric = undefined;
      state.fabricSupplier = null;
      state.isEditing = false;
      // Set available productVariants for this product

      state.availableVariants = ["male"];
      if (action.payload?.data?.hasfemalemodel === true) {
        state.availableVariants.push("female");
      }
      // Reset productVariant if the variant is not available for this product
      if (!state.availableVariants.includes(state.productVariant)) {
        state.productVariant = state.availableVariants[0];
      }
    },
    setSelectedFabric: (
      state,
      action: PayloadAction<{
        fabric: Fabric;
        colorOption: BaseColorOption;
      }>,
    ) => {
      state.fabric = {
        ...(state.fabric || {}),
        [action.payload.colorOption]: action.payload.fabric,
      };
      state.fabricSupplier = action.payload.fabric.data?.supplier || null;
    },
    setSelectedProductOptions: (
      state,
      action: PayloadAction<ProductOption[]>,
    ) => {
      const options = filterValidProductOptions(
        setProductOptionVariantPrice(action.payload, state),
      );
      // Fix to ignore for configLoader with no product options.
      if (options.length === 0 && state.productOptions.length === 0) {
        return;
      }
      state.productOptions = options;
    },
    setProductModelScreenshot: (state, action: PayloadAction<string>) => {
      state.productModelPicture = action.payload;
    },
    setProjectLogo: (state, action: PayloadAction<ProjectLogo>) => {
      const projectLogo = action.payload;
      state.projectLogos = [
        ...state.projectLogos.filter((curr) => curr.id !== projectLogo.id),
        projectLogo,
      ];
    },
    updateProjectLogo: (state, action: PayloadAction<ProjectLogo>) => {
      const projectLogo = action.payload;
      const current = state.projectLogos.find(
        (curr) => curr.id === projectLogo.id,
      );
      if (!current) {
        state.projectLogos.push(action.payload);
      } else {
        state.projectLogos = [
          ...state.projectLogos.filter((curr) => curr.id !== projectLogo.id),
          {
            ...current,
            ...action.payload,
          },
        ];
        // Update pricing for the selected ProjectOptions
        if (state.productOptions) {
          state.productOptions = filterValidProductOptions(
            setProductOptionVariantPrice(state.productOptions, state),
          );
        }
      }
    },
    removeProjectLogo: (state, action: PayloadAction<ProjectLogo>) => {
      state.projectLogos = [
        ...state.projectLogos.filter((curr) => curr.id !== action.payload.id),
      ];
      // Update pricing for the selected ProjectOptions
      if (state.productOptions) {
        state.productOptions = filterValidProductOptions(
          setProductOptionVariantPrice(state.productOptions, state),
        );
      }
    },
    setProductVariant: (state, action: PayloadAction<ProductVariant>) => {
      state.productVariant = action.payload;
    },
    setCompleteYourLookFabrics: (
      state,
      action: PayloadAction<CompleteYourLookFabrics>,
    ) => {
      const { main, contrast } = action.payload;
      state.completeYourLookFabrics = {
        main,
        contrast,
      };
    },
    resetCompleteYourLookFabrics: (state) => {
      state.completeYourLookFabrics = null;
    },
    setProductInitDone: (state) => {
      state.productInitDone = true;
    },
  },
});

export const {
  setIsEditing,
  setConfigLoader,
  setConfigId,
  setProjectId,
  setSelectedProductGroup,
  setSelectedProduct,
  setSelectedFabric,
  setSelectedProductOptions,
  setProductModelScreenshot,
  updateProjectLogo,
  removeProjectLogo,
  setProductVariant,
  setCompleteYourLookFabrics,
  resetCompleteYourLookFabrics,
  resetConfig,
  resetConfigId,
  setProductInitDone,
} = configSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsEditing = (state: RootState) => state.config.isEditing;
export const selectConfigLoader = (state: RootState) =>
  state.config.configLoader;
export const selectConfigId = (state: RootState) => state.config.id;
export const selectProjectId = (state: RootState) => state.config.projectId;
export const selectProductGroup = (state: RootState) =>
  state.config.productGroup;
export const selectProduct = (state: RootState): Product | undefined =>
  state.config.product;
export const selectFabric = (
  state: RootState,
): { main?: Fabric; contrast?: Fabric } | undefined => state.config.fabric;
export const selectFabricSupplier = (state: RootState) =>
  state.config.fabricSupplier;
export const selectProductOptions = (state: RootState): ProductOption[] =>
  state.config.productOptions;
export const selectProductModelPicture = (state: RootState) =>
  state.config.productModelPicture;
export const selectProjectLogos = (state: RootState) =>
  state.config.projectLogos;
export const selectProjectLogoPlacable = (state: RootState) =>
  typeof state.config.product !== "undefined";
export const selectProductVariant = (state: RootState) =>
  state.config.productVariant;
export const selectAvailableProductVariants = (state: RootState) =>
  state.config.availableVariants;
export const selectHasProduct = (state: RootState) =>
  typeof state.config.product !== "undefined";
export const selectCompletYourLookFabric = (state: RootState) =>
  state.config.completeYourLookFabrics;

export const selectIsConfigInCart = (state: RootState):boolean => 
   typeof state.cart.products.find((item:CartProduct) => item.id === state.config.id) !== 'undefined';

export const selectProductInitDone = (state:RootState):boolean => state.config.productInitDone;

const filterValidProductOptions = (productOptions: ProductOption[]) =>
  productOptions.filter((curr) => curr !== null);

function setProductOptionVariantPrice(
  productOptions: ProductOption[],
  state: ConfigState,
) {
  return productOptions.map((productOption) => {
    if (isProductOptionWithLogo(productOption)) {
      const variantPrice = calcProductOptionVariantPrice(
        productOption,
        state.projectLogos,
        state.fabricSupplier,
      );
      if (variantPrice !== false) {
        return {
          ...productOption,
          data: {
            ...productOption.data,
            price: variantPrice,
          },
        };
      }
    }
    return productOption;
  });
}

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
