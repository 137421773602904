import { ProductOption } from "../../types";
import { FC, SyntheticEvent, useMemo } from "react";
import { useGetDocumentByTypeQuery } from "../../api/prismic";
import { setSelectedProductOptions } from "../../store/reducers/configReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getPrismicTranslation } from "../../utils";
import { selectLanguage } from "../../store/reducers/uiReducer";
import {handleConflictOptions, handleOptionsOnRemove} from "./utils";

interface ConfiguratorSelectorSubProductOptionProps {
  parentOption: ProductOption;
  id: string;
  defaultOption?: ProductOption[];
  close?: () => void;
}


const ConfiguratorSelectorSubProductOption: FC<
  ConfiguratorSelectorSubProductOptionProps
> = ({ defaultOption, id, parentOption, close }) => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const isSelected = () => {
    return defaultOption?.some((option) => option?.id === id);
  };

  const { data: productOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const getProductOption = (id: string) => {
    return productOptions?.find(
      (productOption: ProductOption) => productOption?.id === id,
    );
  };

  const productOption = getProductOption(id);

  const handleSelectOption = (e: SyntheticEvent) => {
    e.stopPropagation();
    let selectedOptions = defaultOption || [];
    const alreadySelected = selectedOptions?.find(
      (option) => option?.id === id,
    );

    // remove sub options
    parentOption?.data?.children?.forEach((child: any) => {
      selectedOptions = selectedOptions.filter(
        (item) => item?.id !== child.option?.id,
      );
    });

    // select/deselect current sub option
    selectedOptions = alreadySelected
      ? selectedOptions.filter((item) => item?.id !== id)
      : [...selectedOptions, productOption];

    // select/deselect parent option
    const hasChildrenSelected = parentOption?.data?.children?.some(
      (child: any) =>
        selectedOptions?.find((option) => option.id === child.option?.id),
    );
    const parentSelected = selectedOptions?.find(
      (option) => option?.id === parentOption?.id,
    );

    selectedOptions =
      hasChildrenSelected && !parentSelected
        ? [...selectedOptions, parentOption]
        : !hasChildrenSelected
        ? selectedOptions.filter((item) => item?.id !== parentOption?.id)
        : selectedOptions;


    if (alreadySelected) {
      dispatch(setSelectedProductOptions(handleOptionsOnRemove(productOption, selectedOptions)));
    } else {
      dispatch(setSelectedProductOptions(handleConflictOptions(productOption, selectedOptions, productOptions)));
    }

    close && close();
  };

  const optionName = useMemo(() => {
    return getPrismicTranslation(language, "name", productOption.data);
  }, [language, productOption]);

  return (
    <div
      onClick={handleSelectOption}
      className={`${
        isSelected() ? "bg-primaryLight" : "bg-white"
      } border rounded border-gray p-2`}
    >
      <div
        title={optionName}
        className="flex flex-wrap items-center gap-2 cursor-pointer text-black"
      >
        <img
          className="mr-2"
          src={productOption?.data.image?.thumbnail?.url || "placeholder.png"}
          alt={optionName}
        />
        <span>{optionName}</span>
        {productOption?.data.price && (
          <div className="ml-auto">{productOption.data.price}€</div>
        )}
      </div>
    </div>
  );
};

export default ConfiguratorSelectorSubProductOption;
