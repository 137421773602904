import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectProjectLogoPlacable,
  selectProjectLogos,
} from "../../../store/reducers/configReducer";
import { ProjectLogoItem } from "./LogoItem";
import { ConfiguratorSelector } from "../ConfiguratorSelector";
import { productLogoPanelOpen } from "../../../store/reducers/uiReducer";
import { logoNames } from "./config";

const ProjectLogos: React.FC = () => {
  const projectLogos = useAppSelector(selectProjectLogos);
  const projectLogoPlacable = useAppSelector(selectProjectLogoPlacable);
  const dispatch = useAppDispatch();
  const projectLogoNames =
    projectLogos.length > 0
      ? projectLogos.map((curr) => `project_${curr.logoName}`).sort().join(", ")
      : undefined;

  return (
    <ConfiguratorSelector
      tabKey={"projectLogos"}
      hasOptions={projectLogoPlacable}
      selected={projectLogoNames}
      title="config_option_title_5"
      onClick={(isOpen) => dispatch(productLogoPanelOpen(isOpen))}
    >
      <>
        {logoNames.map((logoName) => (
          <ProjectLogoItem key={logoName} logoName={logoName} data={projectLogos.find(item => item.logoName === logoName)} />
        ))}
      </>
    </ConfiguratorSelector>
  );
};

export { ProjectLogos };
