import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onDeferChange: (value: string, name: string) => void;
  name: string;
  defaultValue?: string;
  deferTimeout?: number;
}

const TextArea: React.FC<TextAreaProps> = ({ onDeferChange, name, defaultValue, deferTimeout = 1000, ...props }) => {
  const [text, setText] = useState<string>(defaultValue || '');

  const debouncedChangeHandler = useCallback(debounce(onDeferChange, deferTimeout), [onDeferChange]);

  useEffect(() => {
    debouncedChangeHandler(text, name);
  }, [text, name, debouncedChangeHandler]);

  return (
    <textarea
      value={text}
      onChange={(e) => setText(e.target.value)}
      {...props}
    />
  );
};

export default TextArea;

