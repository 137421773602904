import PrismicTranslation from "../PrismicTranslation";

interface SwitchProps {
  onChange: (value: string) => void;
  labelA: string;
  labelB: string;
  valueA: string;
  valueB: string;
  initial: string;
}

export const Switch: React.FC<SwitchProps> = ({
  onChange,
  labelA,
  labelB,
  valueA,
  valueB,
  initial,
}) => {
  const handleToggle = (value: string) => {
    onChange(value);
  };
  const left = initial === valueA;

  return (
    <div
      className={`shadow rounded-full h-12 flex p-2 gap-2 items-center absolute right-4 top-4 font-bold bg-white`}
    >
      <div
        className={`w-full flex justify-center px-4  py-2 rounded-full ${
          left ? "bg-primary text-white" : ""
        }`}
      >
        <button onClick={() => handleToggle(valueA)}>
          <PrismicTranslation field={labelA} simple />
        </button>
      </div>
      <div
        className={`w-full flex justify-center px-4 py-2 rounded-full ${
          !left ? "bg-primary text-white" : ""
        }`}
      >
        <button onClick={() => handleToggle(valueB)}>
          <PrismicTranslation field={labelB} simple />
        </button>
      </div>
    </div>
  );
};
