import { LookImageData } from ".";

const LookImageListItem = ({
  lookImage,
  lookImageKey,
}: {
  lookImage: LookImageData;
  lookImageKey: string;
}) => {
  if (lookImage.productPicture && lookImage.hasImage) {
    return (
      <div>
        <img
          src={lookImage.productPicture}
          alt={lookImageKey}
          title={lookImageKey}
        />
        <small>{lookImageKey}</small>
      </div>
    );
  }

  if (lookImage.productPicture && !lookImage.hasImage) {
    return (
      <div>
        <small>{lookImageKey}</small>
      </div>
    );
  }
  return null;
};

export const LookImageList = ({
  lookImages,
}: {
  lookImages: Array<[string, LookImageData]>;
}) => {
  return (
    <div className="flex flex-wrap flex-row gap-2">
      {lookImages.map((entry: [string, LookImageData]) => (
        <LookImageListItem
          key={entry[0]}
          lookImageKey={entry[0]}
          lookImage={entry[1]}
        />
      ))}
    </div>
  );
};

export default LookImageList;
