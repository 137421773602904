import { ConfiguratorSelector } from "../ConfiguratorSelector";
import {
  selectConfigId,
  selectConfigLoader,
  selectProduct,
  selectProductOptions,
  setSelectedProductOptions,
  selectFabricSupplier,
  setProductInitDone,
} from "../../../store/reducers/configReducer";
import { ConfigLoader, Product, ProductOption } from "../../../types";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import ProductFeature from "./ProductFeature";
import {
  getPrismicTranslation,
  sortProductOptionsByPriority,
  updateProductOption,
} from "../../../utils";
import {handleConflictOptions, handleOptionsOnRemove} from "../utils";
import {selectLanguage} from "../../../store/reducers/uiReducer";

const mergeConfigLoaderProductOption = (
  configLoader: ConfigLoader | undefined,
  productOption: ProductOption,
): ProductOption => {
  if (configLoader && configLoader.productOptions) {
    const configLoaderProductOption = configLoader.productOptions.find(
      (curr) => curr.id === productOption.id,
    );
    if (configLoaderProductOption) {
      return {
        ...productOption,
        data: {
          ...productOption.data,
          color_option:
            configLoaderProductOption.colorOption ||
            productOption.data.color_option,
          logo_option:
            configLoaderProductOption.logoOption ||
            productOption.data.logo_option,
        },
      } as ProductOption;
    }
  }
  return productOption;
};

const FeatureSelector = () => {
  const dispatch = useAppDispatch();
  const configId = useAppSelector((state) => selectConfigId(state));
  const language = useAppSelector(selectLanguage);
  const configLoader = useAppSelector((state) => selectConfigLoader(state));
  const product = useAppSelector<Product | undefined>((state) =>
    selectProduct(state),
  );
  const selectedProductOptions = useAppSelector<ProductOption[] | undefined>(
    (state) => selectProductOptions(state),
  );
  // Should be true after all related data is loaded and used once
  const [initialProductInitDone , setInitialProductInitDone] = useState(false);

  const fabricSupplier = useAppSelector(selectFabricSupplier);

  const { data: productOptions, isSuccess } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  const getProductSpecOptions = () => {
    if (!product || !productOptions) return [];

    return (
      productOptions
        .filter((productOption: ProductOption) =>
          product.data.product_options.some(
            (mainFabric: any) =>
              mainFabric.product_option.id === productOption.id,
          ),
        )
        .sort(sortProductOptionsByPriority(product))
        // Add configLoader product options like selected fabric color and logo to ProductOption
        .map((productOption: ProductOption) => {
          return mergeConfigLoaderProductOption(configLoader, productOption);
        })
    );
  };

  // Reset when switching products
  useEffect(() => {
    if (product) {
      setInitialProductInitDone(false);
    }
  }, [product]);

  useEffect(() => {
    if (initialProductInitDone) return;
    if (!productOptions?.length) return;
    const options = getProductSpecOptions();

    if (!selectedProductOptions?.length && options?.length) {
      setInitialProductInitDone(true);
      dispatch(setProductInitDone());
      if (configId && configLoader) {
        const selectedOptions = productOptions.filter(
          (option: ProductOption) =>
            configLoader.productOptions?.some(
              (productConfigOption) => productConfigOption.id === option.id,
            ),
        );
        dispatch(
          setSelectedProductOptions(
            selectedOptions.map((option: ProductOption) => {
              return mergeConfigLoaderProductOption(configLoader, option);
            }),
          ),
        );
      } else {
        dispatch(
          setSelectedProductOptions(
            options
              .filter((option: ProductOption) => option.data.default)
              .map((option: ProductOption) => ({
                ...option,
                data: {
                  ...option.data,
                  color_option: option.data.color_option || "main",
                },
              })),
          ),
        );
      }
    }
  }, [
    isSuccess,
    productOptions,
    product,
    configLoader,
    configId,
    dispatch,
    selectedProductOptions,
    initialProductInitDone,
    getProductSpecOptions,
  ]);

  const addOrRemoveOption = (option: ProductOption) => {
    if (!selectedProductOptions) return;

    const isSelected = selectedProductOptions.some(
      (item) => item?.id === option?.id,
    );

    if (isSelected) {
      dispatch(setSelectedProductOptions(handleOptionsOnRemove(option, selectedProductOptions)));
    } else {
      dispatch(setSelectedProductOptions(handleConflictOptions(option, selectedProductOptions, productOptions)));
    }
  };

  const onOptionChange = (option: ProductOption) => {
    if (!selectedProductOptions) return;
    dispatch(
      setSelectedProductOptions(
        updateProductOption(option, handleConflictOptions(option, selectedProductOptions, productOptions)),
      ),
    );
  };

  return (
    <ConfiguratorSelector
      tabKey={"options"}
      hasOptions={getProductSpecOptions()?.length > 0}
      selected={selectedProductOptions
        ?.map((option) => getPrismicTranslation(language, "name", option?.data))
        .join(", ")}
      title="config_option_title_4"
    >
      <div className="flex flex-col flex-wrap items-center justify-center gap-1">
        {getProductSpecOptions()?.map((option: ProductOption) => (
          <ProductFeature
            key={option.id}
            defaultOption={selectedProductOptions}
            selectOption={addOrRemoveOption}
            onColorChange={onOptionChange}
            option={option}
            selectedProductOptions={selectedProductOptions}
            fabricSupplier={fabricSupplier}
          />
        ))}
      </div>
    </ConfiguratorSelector>
  );
};

export default FeatureSelector;
