import { ReactNode } from "react";
import { useGetDocumentByTypeQuery } from "./api/prismic";

export function PrismicStaticDataManager({ children }: { children: ReactNode }) {
  useGetDocumentByTypeQuery({ type: "fabric", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "product", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "product_group", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "product_option", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "settings", lang: "en-us" });
  useGetDocumentByTypeQuery({ type: "supplier", lang: "en-us" });

  return <>{children}</>;
}
