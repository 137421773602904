import React, { useCallback } from "react";
import { useGetDocumentByTypeQuery } from "../../../api/prismic";
import { ProductScene } from "../../AssetManager/ProductScene";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  selectFabric,
  selectProduct,
  selectProductModelPicture,
  selectProductOptions,
  setSelectedFabric,
  setSelectedProduct,
  setSelectedProductOptions,
} from "../../../store/reducers/configReducer";

import { Fabric, FabricColor, Product, ProductOption } from "../../../types";
import ColorOption from "../../Configurator/FabricSelector/ColorOption";
import { sortProductOptionsByPriority } from "../../../utils";
import { LookImageList } from "./LookImageList";
import { LoopDefer } from "./LoopDefer";
import { FetchExistingImages } from "./FetchExistingImages";
import ShowExistingImages from "./ShowExistingImages";

interface ColorOptionCheckProps {
  prefix: keyof FabricColor;
  fabric: Fabric | undefined;
}

export const ColorOptionCheck = ({ fabric, prefix }: ColorOptionCheckProps) => {
  if (!fabric) {
    return null;
  }
  return (
    <>
      <small>id:{fabric.id}</small>
      <ColorOption prefix={prefix} option={fabric} onClick={() => {}} />
    </>
  );
};

export interface LookImageData {
  product: Product;
  mainFabric: Fabric;
  contrastFabric: Fabric;
  productPicture: string | undefined; // base64 png
  hasImage?: boolean;
}

const uploadLookImage = async (id: string, lookImageData: LookImageData) => {
  try {
    await fetch("/look-images-api/upload-base64", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ key: id, base64: lookImageData.productPicture }),
    });
    return true;
  } catch (error) {
    console.error("Error uploading image:", error);
    return false;
  }
};

function lookImageId(lookImageData: LookImageData): string {
  return `${lookImageData.product.id}-${lookImageData.mainFabric.id}-${lookImageData.contrastFabric.id}`;
}

export function lookImageUrl(lookImageId: string): string {
  const timestamp = new Date().getTime();
  return `${lookImageId}.png?${timestamp}`;
}

function filterProductDefaultOptions(
  product: Product | undefined,
  allProductOptions: ProductOption[],
) {
  if (!product) {
    return [];
  }
  return (
    allProductOptions
      .filter((productOption: ProductOption) =>
        product.data.product_options.some(
          (mainFabric: any) =>
            mainFabric.product_option.id === productOption.id,
        ),
      )
      .sort(sortProductOptionsByPriority(product))
      // Filter and collect only default options
      .filter((option: ProductOption) => option.data.default)
      .map((option: ProductOption) => ({
        ...option,
        data: {
          ...option.data,
          color_option: option.data.color_option || "main",
        },
      }))
  );
}

export const CompleteYourLookImageGenerator = () => {
  const [lookImages, setLookImages] = React.useState(
    new Map<string, LookImageData>(),
  );

  const [currentLookImageId, setCurrentlookImageId] =
    React.useState<string>("");
  const counter = React.useRef("-");

  const dispatch = useAppDispatch();

  // Get a list of all fabrics
  const { data: allFabrics } = useGetDocumentByTypeQuery({
    type: "fabric",
    lang: "en-us",
  });

  // Get a list of all products
  const { data: allProducts } = useGetDocumentByTypeQuery({
    type: "product",
    lang: "en-us",
  });

  // Get a list of all product options
  const { data: allProductOptions } = useGetDocumentByTypeQuery({
    type: "product_option",
    lang: "en-us",
  });

  // Selected Fabric Colors
  const fabric = useAppSelector(selectFabric);
  // Selected Product Options
  const product = useAppSelector(selectProduct);
  // Selected productOptions
  const selectedProductOptions = useAppSelector<ProductOption[] | undefined>(
    selectProductOptions,
  );
  // Selected Product Options
  const productModelImage = useAppSelector(selectProductModelPicture);

  // Should be productId after initial product setup is done,  false otherwise.
  // Initial product setup is dispatching the product and default product options to the store
  const [initialProductInitDone, setInitialProductInitDone] = React.useState<
    boolean | string
  >("");

  // Setup lookImages from loaded products and fabrics data
  React.useEffect(() => {
    const newLookImages = new Map<string, LookImageData>();
    if (allProducts && allFabrics) {
      for (const product of [...allProducts].reverse()) {
        for (const mainFabric of product.data.main_fabrics) {
          for (const contrastFabric of product.data.contrast_fabrics) {
            // Product fabric contains only partial fabric data, lookup fabric in Fabrics for full fabric data
            const mainFabricData = allFabrics.find(
              (curr: Fabric) => curr.id === mainFabric.fabric.id,
            );
            const contrastFabricData = allFabrics.find(
              (curr: Fabric) => curr.id === contrastFabric.fabric.id,
            );
            const lookImageData = {
              product,
              mainFabric: mainFabricData,
              contrastFabric: contrastFabricData,
              productPicture: undefined,
            };

            newLookImages.set(lookImageId(lookImageData), lookImageData);
          }
        }
      }
      setLookImages(newLookImages);
    }
  }, [allProducts, allFabrics]);

  // Update redux store with product and fabrics
  React.useEffect(() => {
    const lookImage = lookImages.get(currentLookImageId);
    if (lookImage) {
      if (
        initialProductInitDone !== lookImage.product.id &&
        initialProductInitDone !== false
      ) {
        // Set product
        dispatch(setSelectedProduct());
        // Timeout is quickfix for ProductModel loaded scene not switching to "undefined" before loading the actual model when using
        // window.timeout to loop the lookImage data. Not an issue when clicking the "next" button.
        window.setTimeout(() => {
          dispatch(setSelectedProduct(lookImage.product));
          setInitialProductInitDone(false);
        }, 50);
      }
      dispatch(
        setSelectedFabric({
          fabric: lookImage.mainFabric,
          colorOption: "main",
        }),
      );
      dispatch(
        setSelectedFabric({
          fabric: lookImage.contrastFabric,
          colorOption: "contrast",
        }),
      );
    }
  }, [currentLookImageId, dispatch, lookImages, initialProductInitDone]);

  // Set default Product Options on product
  React.useEffect(() => {
    const lookImage = lookImages.get(currentLookImageId);
    if (lookImage && initialProductInitDone === false && allProductOptions) {
      const defaultProductOptions = filterProductDefaultOptions(
        lookImage.product,
        allProductOptions,
      );
      // Set default product options
      dispatch(setSelectedProductOptions(defaultProductOptions));
      setInitialProductInitDone(lookImage.product.id);
    }
  }, [
    currentLookImageId,
    dispatch,
    lookImages,
    initialProductInitDone,
    allProductOptions,
  ]);

  // Set rendered productmodel image as lookImage productmodel image
  React.useEffect(() => {
    if (!productModelImage) {
      return;
    }
    const lookImage = lookImages.get(currentLookImageId);
    if (
      !lookImage ||
      initialProductInitDone === false ||
      lookImage?.product.id !== initialProductInitDone
    ) {
      return;
    }

    lookImage.productPicture = productModelImage;
  }, [
    productModelImage,
    currentLookImageId,
    initialProductInitDone,
    lookImages,
  ]);

  const onTick = (
    key: string,
    _value: any,
    currentIndex: number,
    totalItems: number,
  ) => {
    counter.current = `${currentIndex + 1}/${totalItems}`;
    setCurrentlookImageId(key);
  };

  const onComplete = useCallback(
    (key: string) => {
      const lookImage = lookImages.get(key);
      if (lookImage && lookImage.productPicture) {
        uploadLookImage(currentLookImageId, lookImage);
      }
    },
    [currentLookImageId, lookImages],
  );

  const mergeExistingLookImages = useCallback(
    (items: string[]) => {
      const newLookImages = new Map<string, LookImageData>(
        Array.from(lookImages.entries()),
      );
      items.forEach((item) => {
        newLookImages.delete(item);
      });
      setLookImages(newLookImages);
      counter.current = `1/${newLookImages.size}`;
    },
    [lookImages],
  );

  return (
    <>
      <div className="flex m-4">
        <div className="flex-grow">
          {/**  PRODUCT DATA */}
          <div className="p-4">
            <div>Products count: {allProducts?.length} </div>
            <div>Fabrics count: {allFabrics?.length} </div>
            <div>Look Images count: {lookImages.size} </div>
          </div>

          <div className="p-4">
            <div>product: {product?.data?.name || "-"}</div>
            <div>
              fabric: {fabric?.main?.id || "-"} {fabric?.contrast?.id || "-"}
            </div>
            <div>
              product default options:{" "}
              {allProductOptions &&
                filterProductDefaultOptions(
                  lookImages.get(currentLookImageId)?.product,
                  allProductOptions,
                ).length}
            </div>
            <div>
              product selected options: {selectedProductOptions?.length}
            </div>
            <div>currentLookImageId: {currentLookImageId}</div>
            {/**  FABRIC COLORS */}
            <div className="flex gap-x-2">
              fabric colors:
              {lookImages && (
                <ColorOptionCheck
                  prefix="main"
                  fabric={lookImages.get(currentLookImageId)?.mainFabric}
                />
              )}
              {lookImages.get(currentLookImageId)?.mainFabric?.data?.web_format}
              {lookImages && (
                <ColorOptionCheck
                  prefix="contrast"
                  fabric={lookImages.get(currentLookImageId)?.contrastFabric}
                />
              )}
              {
                lookImages.get(currentLookImageId)?.contrastFabric?.data
                  ?.web_format
              }
            </div>
          </div>
          <div className="font-bold p-4">{counter.current}</div>

          {/**  ACTIONS */}
          <div className="m-4 flex flex-col gap-y-4">
            <div className="flex flex-row gap-x-4">
              <FetchExistingImages onComplete={mergeExistingLookImages} />
              <ShowExistingImages items={lookImages} />
            </div>

            <LoopDefer
              items={lookImages}
              onTick={onTick}
              onComplete={onComplete}
              marker={productModelImage}
            />
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          minHeight: 640,
          height: 640,
          width: 640,
          opacity: product ? 1 : 0.25,
        }}
      >
        <ProductScene />
      </div>
      <div className="m-4">
        {/**  PRODUCT MODEL SCREENSHOT */}
        {productModelImage && <img src={productModelImage} alt="product" />}
      </div>
      <div className="m-4">
        <div className="font-bold p-4">{counter.current}</div>
        <LookImageList lookImages={Array.from(lookImages.entries())} />
      </div>
    </>
  );
};

export default CompleteYourLookImageGenerator;
